.nav-main-cls {
    background-color: #232c63;
}

.top-nav-container-cls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.topnav-logo-a-cls {
    display: flex;
    align-items: center;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.topnav-logo-img-cls {
    height: 5rem;
}

.topnav-login-main-div {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.topnav-login-a-tag-cls {
    font-size: 0.875rem;
    color: #4a90e2;
    text-decoration: underline;
}

.topnav-menu-main-cls {
    background-color: #37415194;
}

.topnav-menu-container-cls {
    line-height: 45px;
    padding: 0 50px;
}

.topnav-menu-div-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topnav-menu-toggle-button-cls {
    color: #1a202c;
}

.topnav-menu-toggle-button-cls:focus {
    outline: none;
}

.topnav-menu-toggle-svg {
    width: 1.5rem;
    height: 1.5rem;
}

.topnav-menu-ul-cls {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    margin-top: 0;
    margin-right: 2rem;
    margin-left: 2rem;
}

.topnav-menu-small-screen-cls {
    display: none;
}

.topnav-menu-item-cls {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #1a202c;
}

.topnav-menu-item-cls:hover {
    text-decoration: underline;
}

.topnav-menu-item2-cls {
    position: relative;
}

.cursor-pointer {
    cursor: pointer;
}

@media (max-width: 767px) {
    .topnav-menu-small-screen-cls {
        display: none;
    }

    .topnav-menu-ul-cls {
        display: flex !important;
    }
}

.topnav-menu-div-cls {
    display: flex;
    justify-content: space-between;
}

.topnav-lang-dropdown-cls {
    border: none;
    box-shadow: none;
    background-color: transparent;
}

.product-card-active {
    color: #232c63;
}

.product-card {
    color: #bfbfbf;
    /* color: #060606; */
}

.card-grid-view-img {
    height: 11.5rem;
    object-fit: cover;
    width: 85%;
    /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25); */
}

.product-grid-view-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    color: #232C63;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    font-family: 'NotoSans-Medium';
}
.service-provider-button{
margin-top: 15px;
padding-top: 29px;
}

.product-final-price {
    color: #232C63;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: 'NotoSans-Medium';
}

span.farm-final-price {
    font-size: 18px;
    font-weight: 600;
    color: #0a0a0a !important;

}

.qty-input {
    width: 15%;
    margin: 0 5px;
    text-align: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.qty-add-btn,
button.qty-minus-btn {
    padding: 10px;
    width: 12%;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-quantity-buttons-div {
    height: 35px !important;
}

.product-grid-view-desc {
    color: #000000c9 !important;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    font-size: 14px;
    line-height: 1.5;
}

.product-list-view-desc {
    color: #080808 !important;
    /* color: #757575 !important; */
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    line-height: 1.6;
    font-size: 13px;
    /* font-size: 14px; */
    font-weight: 400;
    width: 70%;
    text-align: justify;
}

.product-grid-view-btn-div {
    text-align: center;
}

.product-list-view-btn-div {
    text-align: end;
}

.pagination-container {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-right: 10px;
}

.pagination-item {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #fbfbfb;
    margin: 0 5px;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #fbfbfb;
    color: #232c63;
    font-size: 18px;
}

li.pagination-item.active {
    color: #fff;
    background-color: #232c63;
}

button.set-default-btn {
    background-color: #dc7f92 !important;
    padding: 4px;
    font-size: 12px;
    font-weight: 500;
}

.pagination-previous,
.pagination-next {
    cursor: pointer;
    border: 1px solid #dc7f92;
    margin: 0 10px;
    border-radius: 10px;
    background-color: #dc7f92;
    height: 35px;
    width: 35px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-item.active a {
    color: white !important;
}

.pagination-item a,
.break a {
    color: #3f3c3c !important;
}

.pagination-previous a,
.pagination-next a {
    color: #ffffff !important;
}

.small-screen-filter-modal {
    display: none;
}

.small-screen-filter-cantainer {
    margin-top: 40px;
    margin-bottom: 40px;
}

img.product-details-img {
    width: 100%;
    height: 32rem;
    object-fit: contain;
}

.product-details-img, 
.product-details-video {
    width: 100%;
    height: 32rem; /* Adjust the height as needed */
    object-fit: contain; /* Ensures the image or video fits within the container */
}

@media (max-width: 768px) {
    .product-details-img, 
    .product-details-video {
        height: auto; /* Automatically adjust the height for smaller screens */
    }
}   

.product-details-img-col {
    border-radius: 6px;
    border: 1px solid #BABABA;
    position: relative;
    height: 100%; /* Default height for all screen sizes */
    background-color: #ffffff;
}

.custom-card-container {
    background-color: white;
    padding: 20px; /* Adjust padding as needed */
    border-radius: 10px; /* Optional: Add rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
}

/* Apply height specifically for screens 1024px and wider */
@media (min-width: 1024px) {
    .product-details-img-col { /* Corrected the class name */
        height: 100%; /* Set height to 100% for screens 1024px and wider */
    }
}
@media (min-width: 2560px) {
    .product-details-img-col {
        height: 98%; /* Set height to 98% for screens 2560px wide */
    }
}
@media (min-width: 1440px) {
    .img.product-details-img {
        height: 27rem;/* Set height to 98% for screens 2560px wide */
    }
}
.product-details-name {
    text-decoration: underline;
    color: #df7d92;
}

.product-details-desc {
    margin-top: 10px;
    line-height: 1.6;
    color: #080808 !important;
    /* color: #757575; */
    font-size: 13px;
    /* font-size: 14px; */
    font-style: normal;
    letter-spacing: 0.28px;
}

.product-details-font {
    line-height: 1.5;
}

.product-details-qty-minus {
    margin-right: 10px;
}

.product-details-qty-plus {
    margin-left: 10px !important;
}

.product-details-quantity-input {
    text-align: center;
    border-radius: 5px !important;
}

.product-details-buynow {
    width: 100%;
    height: 45px;
    background-color: #df7d92 !important;
}

.product-details-buynow:hover {
    background-color: #232C63 !important;
    color: white !important;
}

img.cart-product-img {
    width: 7rem;
    height: 7rem;
    object-fit: contain;
}

.cart-qty-minus {
    height: 35px;
    width: 35px;
    font-size: 8px;
    text-align: center;
    margin-right: 5px;
}

.cart-qty-plus {
    height: 35px;
    width: 35px;
    font-size: 8px;
    text-align: center;
    margin-left: 5px !important;
}

.cart-quantity-input {
    width: 50px !important;
    text-align: center;
}

.cart-td-align {
    vertical-align: middle;
}

.checkout-col1 {
    padding: 15px;
    border-radius: 6px;
    background-color: #fbfbfb;
}

.checkout-address-font {
    font-size: 14px;
}

img.checkout-product-img {
    width: 7rem;
    height: 7rem;
    object-fit: contain;
}

.checkout-col2 {
    margin-left: 5px;
    border-radius: 6px;
    padding: 0 5px;
    background-color: #fbfbfb;
}
/* 
.checkout-product-name {
    font-size: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    font-family: 'NotoSans-Medium';
}
 */
 .checkout-product-name {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    /* width: 16ch;  */
    font-family: 'NotoSans-Medium';
}

.checkout-product-qty {
    font-size: 16px;
    color: #060606;
}

.checkout-product-rate {
    font-size: 16px;
    color: #14a44d;
}

.confirm-order-btn {
    width: 100%;
}

@media (min-width: 767px) {
    .productpageviewmore {
        margin-left: 1100px;
    }
}

.change-address-link {
    color: #0000EE !important;
    text-decoration: underline !important;
    cursor: pointer;
    white-space: nowrap;
}


.product-price-filter-slider {
    width: 100%;
    height: 5px;
}

.range-minandmax {
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid #B1B1B1;
    background: #F3F3F3;
    padding: 2px 5px;
}

#rangeInput {
    accent-color: #dc7f92;
}

.fontsize-14 {
    font-size: 14px;
}

.selected-price-range {
    color: lightcoral;
}

.product-notfound-cls {
    text-align: center;
    margin-top: 50px;
}

img.empty-cart-img {
    height: 300px;
    width: 100%;
    object-fit: contain;
    margin: 0px 0;
}

.cart-noproducts-text {
    font-size: 22px;
    margin: 0px 0 13px 0;
    font-weight: 600;
    color: #c2cbcf;
}

.category-filter-li {
    display: flex;
    background-color: #232c63;
    color: white;
}

.category-filter-li-for-AnimalPage {
    display: flex;
    background-color: #232c63;
    color: white;
    justify-content: space-between;
    font-size: 20px;
}

.our-team-card-cls {
    border: 1px solid #0e0d0d;
    border-radius: 8px;
    background-color: #fbfbfb;
    margin-bottom: 24px !important;
    /* width: 363px; */
}



@media (max-width: 991px) {
    .our-team-card-cls {
      width: 100%;
      /* padding: 0 15px; */
    }
  }
  

.out-doctor-card-img-cls {
    /* width: 174px; */ 
    height: 220px;  
    width: 100%;
    /* height: 100%; */
    max-height: 11rem; 
    border-radius: 6px;
  }

  .slick-dots {
    margin-bottom: 23px;
  }

  .img-box {
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
 }
  
  
.out-team-card-img-cls {
    width: 100%;
    height: 13rem;
    border-radius: 6px;
    object-fit: contain;
    padding: 10px 0;
}

.out-team-content-col {
    line-height: 1.7;
}


.out-team-designation-cls {
    color: #060606;

}
button.our-doctor-button-cls {
    width: fit-content;
}

img.consultation-banner-img {
    width: 100%;
    height: auto;
    opacity: 0.6;
}

.consulation-request-form-row.row {
    position: absolute;
    top: 25rem;
}

.consultation-request-form-col1 h6 {
    font-size: 27px;
    margin: 0 50px;
    color: #dd7e92;
    line-height: 1.5;
}

.consulation-request-form-col2.row {
    background-color: #00000085;
    width: 80%;
    padding: 20px;
    border-radius: 10px;
}

h6.consult-form-header {
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
}

label.consult-form-label {
    color: white;
    padding-bottom: 10px;
}

button.consult-form-button {
    background-color: #c77183 !important;
    margin-top: 30px;
}

p.consultation-request-form-content-2.mt-1 {
    font-size: 18px;
    margin: 0 50px;
    color: #534f4fd6;
    line-height: 1.5;
    font-weight: 600;
    padding-top: 60px;
}

.consult-card-cls.card {
    border: 1px solid gray;
    padding: 10px;
}

img.consult-card-img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

h6.consult-card-name {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-top: 10px;
    color: #232c63;
}

.product-filter-submi-btn {
    display: none;
}

.cart-container {
    position: relative;
    display: inline-block;
}

.slider {
    width: 100%;
    height: 5px;
    background: #060606;
}

.slider .thumb {
    width: 20px;
    height: 20px;
    cursor: grab;
    background: #fbfbfb;
    border-radius: 50%;
    border: 1px solid #8E8E8E;
    top: -8px;
}

.footer-col-header {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    padding-top: 20px;
}


.footer-col-content {
    margin-top: 20px;
    list-style: disc;
    text-align: left;
    font-size: 17px;
    line-height: 2;
}

.d-flex.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.footer-btm-img-container {
    padding: 10px 0;
    /* height: 200px; */
}

.footer-bottom-img {
    max-height: 100%;
}


.footer-copyright-text {
    margin-top: 10px;
}

.footer-logo-img {
    height: 75px;
    object-fit: contain;
}


.footer-logo-after-text {
    font-size: 16px;
    font-weight: 300;
}

.footer-contactus-hr {
    border-top: 3px solid white;
    width: 90%;
}

/* .footer-social-icon-section a {
    display: inline-block;
    width: 27px;
    border-radius: 13%;
    background-color: white;
    text-align: center;
    align-items: center;
} */
.footer-social-icon-section a {
    display: inline-block;
    width: 30px;
    height: 30px; 
    margin-right: 10px; 
    border-radius: 13%;
    background-color: white;
    text-align: center;
    line-height: 20px; 
    vertical-align: middle;
}


.footer-social-icons-a {
    color: #232C63;
    font-size: 23px;
}

svg.footer-contactus-phone-icon {
    font-size: 20px;
    margin-right: 5px;
}

svg.footer-contactus-mail-icon {
    font-size: 22px;
    margin-right: 5px;
}

.footer-col2-sectionAPPStore {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end; 
}

.footer-col2-section {
    list-style: none;
    padding: 0;
    width: 150px;
}

.footer-btm-img-container {
    background-color: #eee1d8;
}

p.footer-copyright-text {
    background-color: transparent;
    text-align: center;
    /* margin-top: 10px; */
    font-size: 14px;
    color: #232C63;
}

.cart-badge {
    position: absolute;
    top: -11px;
    right: -13px;
    padding: 4px;
    font-size: 12px;
}

.product-filter-accordion {
    background-color: #232c63;
    border-radius: 0 0 10px 10px;
}

.card.product-list-view-card-div {
    margin: 15px 0;
}

span.product-discount-span {
    position: absolute;
    background-color: #dc7f92;
    color: #060606;
    font-size: 14px;
    /* font-size: 18px; */
    padding: 5px;
    border-radius: 6px 0px 0px 0px;
    top: 0;
    border: none;
    left: 0;
}

.StepButton-d2-0-2-8.completed {
    background-color: #198754 !important;
}

.StepButton-d2-0-2-8.active {
    background-color: #1ac01acc !important;
}

.StepButton-d6-0-2-18.active {
    background-color: #1ac01acc !important;
}

.checkout-coupon-section-div {
    border: 1px solid #e0e0e0;
    margin: 11px 0;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
}

h6.checkout-coupon-code {
    color: #dd7e92;
    text-decoration: underline;
}

svg.checkout-coupon-copy {
    font-size: 20px;
    color: gray;
    cursor: pointer;
}

svg.checkout-coupon-copied-check {
    font-size: 20px;
    border: 1px solid #198754;
    border-radius: 50%;
    padding: 2px;
    color: #198754;
}

.checkout-confirm-order-btn {
    background-color: #198754 !important;
    text-transform: uppercase;
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}
.coupon-code {
    color: #232c63;
    font-weight: bold
}

.product-list-qty-div {
    border: 1px solid lightgray;
    width: 55%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.product-list-qty-main-div {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.qty-modal-btn-col {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

button.prodct-qty-modal-sumbit-btn {
    background-color: #008000bd !important;
    width: 70%;
}

.blog-page-img-cls {
    height: 150px;
    width: 100%;
    object-fit: contain;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

button.video-blog-page-btn {
    height: 215px;
    width: 100%;
}

img.video-blog-page-img {
    height: 13rem;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.video-blog-page-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: visible;
    -webkit-line-clamp: 2;
    font-size: 15px;
    height: 50px;
    margin-top: 10px;
    color: #060606;
    font-weight: 600;
    cursor: pointer;
    padding: 0 10px;
    letter-spacing: 0.44px;
}

.video-blog-page-btn {
    background-color: #fbfbfb;
    border-radius: 10px;
}

.active-menu-cls {
    background-color: #dc7f92 !important;
    padding: 5px 5px 5px 5px !important;
    border-radius: 5px;
    color: #fff !important;
    font-size: 14px;
    /* margin-top: 15px; */
}

.card-grid-view-col-cls {
    margin: 10px 0;
}

.product-list-disc-height {
    height: 98px;
}

svg.service-location-icon {
    font-size: 20px;
    margin-bottom: 2px;
    color: dodgerblue;
}

h6.service-rovider-location {
    font-size: 15px;
    color: #008000c4;
}

small.service-change-location-btn {
    color: royalblue;
    text-decoration: underline;
    cursor: pointer;
}

small.service-change-location-btn:hover {
    color: rgb(22, 49, 128);            
}

.service-provider-location-col {
    line-height: 1;
    /* margin-top: -59px; */
}

.services-change-location-modal-label {
    line-height: 1.8;
    margin: 5px 0;
    color: #000000cf;
}

.services-category-active {
    color: #dc7f92 !important;
}

button.product-listview-viewcart {
    width: 100%;
    background-color: #dc7f92;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.checkout-payment-type {
    line-height: 2;
}

img.checkout-payment-type-img {
    height: 22px;
    object-fit: contain;
    margin: 0 10px 0 5px;
}

svg.order-success-modal-icon {
    font-size: 15vh;
    color: limegreen;
}

svg.order-failed-modal-icon {
    font-size: 15vh;
    color: red;
}

h5.order-success-modal-h5 {
    margin-top: 10px;
    color: #dc7f92;
}

button.order-success-modal-buton {
    width: 75%;
    margin-top: 10px;
}

.home-page-marketplace-card-div {
    background-color: white;
    width: 100%;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid lightgray;
}

img.home-page-marketplace-card-img {
    height: 25vh;
    width: 100%;
    object-fit: cover;
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;
}

.home-page-marketplace-card-content {
    line-height: 1.1;
}

p.home-page-marketplace-card-title {
    font-weight: 600;
    color: #dc7f92;
    text-align: center;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

p.home-page-marketplace-card-place {
    font-size: 14px;
    text-align: center;
    color: dimgray;
}

p.home-page-marketplace-card-age {
    font-size: 14px;
    margin-left: 15px;
}

p.home-page-marketplace-card-price {
    font-size: 15px;
    margin-left: 15px;
}

.home-market-price {
    color: limegreen;
    font-weight: 600;
}

.home-market-label {
    font-weight: 500;
}

.best-seller-name {
    color: #232c63;
    /* display: -webkit-box;
    -webkit-box-orient: vertical; */
    /* overflow: hidden; */
    -webkit-line-clamp: 1;
    background-color: #FFC6D2;
    height: 35px;
    width:190px;  
    padding: 2px;
    border-radius: 0 0 10px 10px;  
    line-height: 35px;
}

.best-seller-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    color: #878787;
    height: 50px;
}

.our-service-description {
    font-size: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    color: #878787;
    height: 50px;
}

.best-seller-table {
    margin-top: 15px;
}

.best-seller-table th {
    font-weight: 500;
}

.best-seller-weight,
.best-seller-price-inr {
    color: #13d72aad !important;
}

.best-seller-btn {
    border: none;
    padding: 12px 50px;
    background: #232c63;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    -moz-transition: background 0.3s ease-in;
    -o-transition: background 0.3s ease-in;
    -webkit-transition: background 0.3s ease-in;
    transition: background 0.3s ease-in;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}

.container-for-bestseller .best-seller-btn:hover {
    background: #dc7f92;
}

.best-seller-container {
    position: relative;
    margin-top: 14px;
    text-align: center;
    /* background: #fff !important; */
    /* box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px -10px 14px rgba(0, 0, 0, 0.1), 0px 11px 61px -47px rgba(0, 0, 0, 0.2); */
    cursor: default;
    /* width: 97% !important; */
    /* margin-left: auto !important; */
    /* margin-right: auto !important; */
}

.slider-arrow-custom-style .slick-prev,
.slider-arrow-custom-style . {
    width: 40px;
    height: 40px;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    background-color: #dc7f92;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
}

.slider-arrow-custom-style .slick-next {
    right: 0%;
}


.home-testimonials-slider .slick-prev,
.home-testimonials-slider .slick-next {
    width: 40px;
    height: 40px;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    background-color: #dc7f92;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
}

.container.best-seller-view-all {
    height: 26.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.marketplace-view-all {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 351px;
    /* height: 21.2rem; */
    border-radius: 8px;
}

h5.best-seller-view-all {
    border: 1px solid #dc7f92;
    border-radius: 50%;
    height: 8rem;
    width: 8rem;
    line-height: 8rem;
    background-color: #dc7f92;
    color: white;
    cursor: pointer;
    text-align: center;
}

h5.best-seller-view-all:hover {
    background-color: #232c63;
    border: 1px solid #232c63;
}

.marketplace-view-all-btn {
    border: 1px solid #dc7f92;
    border-radius: 50%;
    height: 8rem;
    width: 8rem;
    line-height: 8rem;
    background-color: #dc7f92;
    color: white;
    cursor: pointer;
    text-align: center;
    margin: 13.4vh 0;
}

.marketplace-view-all-btn:hover {
    background-color: #232c63;
    border: 1px solid #232c63;
}

img.card-img-top.home-farm-img {
    height: 150px;
    width: 100%;
    object-fit: contain;
}

.home-farm-equipment-card {
    padding: 10px;
    margin-top: 8px;
    width: 100%;
    display: inline-block;
    height: 357px;
}


.home-farm-equipment-card .container {
    height: 100%;
    /* Fill the entire height of the card */
}

.best-farm-equipment-card {
    padding: 10px;
    /* margin-top: 8px;
        width: 100%;
        display: inline-block; */
    /* height: 357px; */
}

.home-farm-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-align: center;
}

.slick-track {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.farm-equipment-view-all {
    height: 432px;
    /* height: 28.1rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    border-radius: 1rem;
}

.farm-equipment-viewall-card {
    margin-top: 14px !important;
}


.farm-equipment-view-all-btn {
    border: 1px solid #dc7f92;
    border-radius: 50%;
    height: 8rem;
    width: 8rem;
    line-height: 8rem;
    background-color: #dc7f92;
    color: white !important;
    cursor: pointer;
    text-align: center;
}

.farm-equipment-view-all-btn:hover {
    background-color: #232c63;
    border: 1px solid #232c63;
}

.home-doctor-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 7px;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
}
/* .Team-Grid-img {
    width: 60vh;
    height: 70vh;
    border-radius: 10%;
    padding: 7px;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
} */

/* .Team-Grid-img {
    width: 100%;
    height: 248px;
    object-fit: cover;
    border-radius: 4%;
  } */

  .Team-Grid-img {
    width: 100%;
    height: 17.875em;
    border-radius: 4%;
    overflow: hidden;
  }
.home-banner-content {
    margin-top: 20vh;
}

.home-banner-text1 {
    font-size: 48px;
    color: #F4F4F4;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6vh;
}

.home-banner-text2 {
    color: #F4F4F4;
    font-size: 22px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: .22px;
    margin-bottom: 6vh;
}

button.home-banner-btn {
    background-color: white !important;
    color: #232c63 !important;
    border-color: transparent !important;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 25px;
}

.navbar-search::placeholder {
    font-size: 12px;
    padding-left: 20px;
}

svg.navbar-search-icon {
    position: absolute;
    top: 65px;
    margin-top: 33px;
    margin-left: 5px;
    font-size: 22px;
    color: #a9acac;
}

ul.topnav-icons-ul {
    list-style: none;
    display: flex;
    gap: 30px;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
}

ul.topnav-icons-ul li {
    display: flex;
    align-items: center;
}

.navbar-language-dropdowm-toggle {
    border: none;
    padding: 0;
    font-size: 14px;
}

.navbar-language-dropdowm-menu.dropdown-menu.show {
    background-color: #232c63;
    border: 1px solid white;
    min-width: 10px !important;
    margin-left: -10px;
    margin-top: 10px;
    line-height: 1.7;
    letter-spacing: 1px;
}

.user-profile-logout-btn {
    background-color: #d73333 !important;
    font-weight: 500;
}

.navbar-language-dropdowm-menu.dropdown-menu.show .dropdown-item:focus,
.navbar-language-dropdowm-menu.dropdown-menu.show .dropdown-item:hover {
    color: #dc7f92 !important;
    background-color: transparent !important;
}

.cart-count-badge {
    position: absolute;
    top: 12px;
    margin-left: 15px;
    font-size: 10px;
    border: 1px solid #dc7f92;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 20px;
    background-color: #dc7f92;
    font-weight: 700;
    cursor: pointer;
}

/* @media (max-width: 992px) {
    .cart-count-badge {
        position: absolute;
        top: 12px !important;
    }
} */

@media (max-width: 576px) {
    .cart-count-badge {
        position: absolute;
        top: 10px !important;
    }
}

span.navbar-login-text {
    font-size: 14px;
    cursor: pointer;
}

.products-dropdown-menu {
    display: none;
}

.our-team-card-name {
    color: #0D1560;
    font-size: 16px;
    text-align: center;
    word-wrap: break-word;
    font-weight: 400;
}
@media (max-width: 424px) {
    .our-team-card-name {
      font-size: 14px; 
    }
  }
  
.our-doctor-call-btn {
    background-color: #dc7f92 !important;
}

.home-doctor-view-all {
    height: 20rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .Doctor-2-img {
      max-width: 100%; 
      height: auto; 
      display: block; 
      margin: 10px auto; 
    }
  }
@media (max-width: 767px) {
    .Doctor-img-fluid {
      max-width: 100%; 
      height: auto; 
      display: block; 
      margin: 10px auto; 
    }
  }
@media (max-width: 767px) {
    .img-fluid {
      max-width: 100%; 
      height: auto; 
      display: block; 
      margin: 10px auto; 
    }
  }

  
.home-blogs-view-all {
    height: 13.03rem;
    /* height: 15.8rem; */
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.home-video-view-all {
    height: 16.8rem;
    /* height: 18.8rem; */
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.product-details-offer {
    top: 10px;
    left: 2%;
}

.fw-600 {
    font-weight: 600;
}

.delivery-address-add {
    color: #1aab2a;
    cursor: pointer;
    font-size: 18px;
    gap: 5px;
    font-family: 'NotoSans-Medium';
}

.loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000094;
    display: flex;
    justify-content: center;
    z-index: 9999;
    padding-top: 15rem;
}

.loading-spinner {
    width: 8vmax;
    height: 8vmax;
    border-right: 4px solid #ffffff;
    border-radius: 100%;
    animation: spinRight 800ms linear infinite;
    position: fixed;
}

.loading-spinner:before,
.loading-spinner:after {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid #ffffff;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
}

.loading-spinner:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid #ffffff;
    animation: none;
}



@keyframes spinLeft {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(720deg);
    }
}

@keyframes spinRight {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.login-user-icon {
    border: 1px solid #dc7f92;
    border-radius: 50%;
    font-size: 25px;
    padding: 2px;
    background-color: white;
}

img.user-profile-page-profile {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid lightgray;
}

/* .user-profile-page-name {
    color: #dc7f92;
    font-weight: 600;
}
.user-profile-page-Blue {
    color: #232c63;
    font-weight: 600;
} */

.user-profile-page-welcome {
    color: #232c63;
    font-weight: 600;
  }
  
  .user-profile-page-name {
    color: #dc7f92;
    font-weight: 600;
  }
  

.user-profile-section-ul {
    padding-left: 0;
    color: #fff;
}

.user-profile-card-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    /* line-height: 2; */
}

.user-profile-active-tab {
    color: #dc7f92;
}

.user-profile-address-div {
    border: 1px solid lightgray;
    padding: 10px;
    margin: 10px 0;
    border-radius: 6px;
    line-height: 2;
    background-color: #fbfbfb;
    width: 84%;
}

.user-profile-address-icons {
    font-size: 18px;
    color: slategray;
}

.user-profile-default-address {
    background-color: #E8E8E8;
}

.user-profile-coupons-tab {
    background-color: #d3d3d33b;
    padding: 10px;
    color: #757575;
    cursor: pointer;
}

.user-profile-coupons-tab-active {
    color: white;
    background-color: #232c63;
}

.user-profile-coupon-card-col {
    position: relative;
    border: 1px solid #b1b1b1;
    border-radius: 5px;
    /* margin: 10px; */
    padding: 15px;
    background-color: #fbfbfb;
}

.user-profile-coupon-card-col {
    border: 1px solid lightgray;
    border-radius: 13px;
    margin-top: 10px;
    margin-right: 10px;
    padding: 15px;
    /* width: 62vh; */
}

.user-profile-coupon-description {
    font-size: 15px;
    color: #000000d1;
    margin-top: 10px;
    padding-top: 20px;
    height: 96px;
}
@media (max-width: 425px) {
    .user-profile-coupon-description {
        height: auto;
    }
}
 
.dashed {
    border-style: dashed;
    border-width: 1px; 
  }
  .coupon-copy-text{
    white-space: nowrap
  }

.user-profile-expiry {
    color: white;
    background-color: darkgray;
    padding: 10px;
    font-weight: 500;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    height: fit-content;
}

.active-coupon-offer {
    margin-top: 10px;
}


.user-profile-Active {
    color: white;
    background-color: #32cd32cf;
    padding: 5px;
    font-weight: 500;
    font-size: 12px;
}

.nocoupons {
    display: flex;
    justify-content: center;
    margin: 50px;
    font-weight: 600;
    font-size: 18px;
}

span.my-order-order-id {
    font-weight: 500;
    color: #dc7f92;
    font-size: 18px;
}

span.my-order-date {
    font-size: 18px;
    color: rgb(12, 12, 12);
    font-weight: 500;
}

.container.my-order-price-container {
    background-color: #7080901a;
    padding: 10px;
    margin: 10px 0;
    line-height: 1.6;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #7080901a;
    line-height: 2;
}

span.my-order-price-label {
    font-weight: 500;
}

span.my-order-price-total {
    font-weight: 700;
}

span.my-order-payment-paid {
    color: green;
    font-weight: 600;
    background-color: #0080004f;
    padding: 2px;
    border-radius: 2px;
    font-size: 14px;
}

span.my-order-payment-pending {
    color: orange;
    font-weight: 600;
    background-color: #ffa50045;
    padding: 2px;
    border-radius: 2px;
    font-size: 14px;
}

span.my-order-price-discount {
    color: green;
}

.user-profile-order-icons-div {
    /* border: 1px solid #dc7f92; */
    padding: 5px 13px;
    font-size: 17px;
    background-color: #232c63;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.user-profile-order-icons-div1 {
    /* border: 1px solid #232c63; */
    padding: 5px 13px;
    font-size:17px;
    background-color: #232c63;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
.new-user-profile-order-icons-div {

    font-size: 17px;
   /* padding-right: 90px; */

}

.tb-file-download {
    color: blue;
    line-height: 31px; /* adjust the value to move the text down */
    vertical-align: middle; /* align the text vertically */

}

span.my-order-Pending {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 21px;
    /* background-color: #DF730F; */
    color: #DF730F;
    border-radius: 4px 9px 0px 5px;
}
span.my-order-On-the-way {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 21px;
    /* background-color: #0F6FDF; */
    color: #0F6FDF;
    border-radius: 4px 9px 0px 5px;
}

span.my-order-Delivered {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 21px;
    /* background-color: #008000; */
    color: #008000;
    border-radius: 4px 9px 0px 5px;
}

span.my-order-Rejected {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 21px;
    /* background-color: #FA1616; */
    color: #FA1616;
    border-radius: 4px 9px 0px 5px;
}



span.my-order-return-Returned {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 21px;
    /* background-color: #04D000; */
    color: #04D000;
    border-radius: 4px 9px 0px 5px;
}
span.my-order-return-in-transit {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 21px;
    /* background-color: #169AFA; */
    color: #169AFA;
    border-radius: 4px 9px 0px 5px;
}

.user-profile-coupon-percentage {
    color: black !important;
    background-color: #dc7f92;
    border-radius: 9px 0px 0px 0px;
    padding: 7px;
    font-size: 14px;
    width: fit-content;
}

.card-transform-none:hover {
    transform: none;
}

img.user-profile-cattle-img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border: 1px solid lightgray;
    border-radius: 50%;
    padding: 2px;
    margin-right: 10px;
    background-color: white;
}

.address-book-address {
    line-height: 1.6;
    margin-left: 1rem;  
}
.farm-address-book-address {
    line-height: 1.6;
    /* margin-left: 1rem; */
}
.Farm-Details-book-address {
    line-height: 1.6;
    margin-inline-start: -21px
}

.user-profile-address-btn {
    background-color: #dc7f92 !important;
    padding: 5px 10px;
}

.user-rofile-select-address {
    color: #0c1d8b;
    font-weight: 500;
    cursor: pointer;
    font-family: 'NotoSans-Medium';
    font-size: 18px;
}

.user-profile-cattle-row {
    background-color: #fbfbfb;
    border: 1px solid #b1b1b1;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding: 15px;
}
.user-testinmonial-home-row {
    background-color: #fbfbfb;
    /* border: 1px solid #b1b1b1; */
    /* margin-left: auto; */
    margin-right: -62px;
    border-radius: 10px;
    padding: 15px;
    width: 50%;
    height: 346px;
    margin-left:8px
}

@media (max-width: 768px) {
    .user-testinmonial-home-row {
        width: 100%; /* Adjust the width to 100% for screens below 768px */
        margin-right: 0; /* Reset the right margin */
        margin-left: 0; /* Reset the left margin */
    }
}

/* .user-profile-cattle-title {
    font-weight: 500;
    font-size: 20px;
    color: #262626;
} */
.farm-input-box{
    width: 111%;
  border:  1px solid #DC7F92;
  border-radius: 0px 3px 3px; 

}
.farm-head-input-box{
    border-radius: 5px 0px 0px 0px;
    border: 1px solid #232C63;
    padding: 0.5rem;
}
.profile-order-status {
    position: relative;
    top: -8px; 
    /* top: -15px;  */
    margin-right: -15px;
}

@media only screen and (max-width: 600px) {
    .profile-order-status {
        position: relative;
    }
}


.user-profile-tab1-details.row {
    margin: 30px 0;
    line-height: 2.3;
    border: 1px solid #b1b1b1;
    background-color: #fbfbfb;
    padding: 15px;
    border-radius: 10px;
    width: 84%;
}
.user-farmdeatil-tab1-details.row {
    /* margin: 30px 0;
    line-height: 2.3; */
    /* border: 1px solid #b1b1b1; */
    background-color: #fbfbfb;
    /* padding: 15px; */
    border-radius: 10px;
    /* width: 84%; */
}

label.user-profile-tab1-label {
    font-weight: 500;
}

.user-profile-farm-cattle-div {
    border: 1px solid #dc7f92;
    margin: 0 10px;
    text-align: center;
    /* padding: 7px;
    background-color: #dc7f92;
    color: white; */
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}

/* test here value box */
.value-box {
    background-color: #fff; 
    padding: 14px;
    border: 1px solid #ececec;
    border-radius: 5px;
    margin-bottom: 10px;
}
.value-box-answer {
    background-color: #fff; 
    padding: 14px;
    border: 1px solid #ececec;
    border-radius: 5px;
    text-align: center;
}

.order-details-modal-item-row.row {
    border: 1px solid lightgray;
    margin: auto;
    border-radius: 5px;
    max-height: 40vh;
    overflow-y: auto;
}

.select-address-add-btn {
    /* padding: 0 10px; */
    margin: 0 18px 10px 10px;
}

button.order-details-call-btn {
    background-color: green !important;
}

.order-details-delivery-address {
    padding: 10px;
    border-radius: 5px;
    color: #000000ad;
    border: 1px solid lightgray;
}

.active-coupon-percentage {
    position: absolute;
    left: 0;
    top: 0;
}

.active-coupon-code-copy {
    /* width: 80%;
    margin-top: 35px;
    border: 1px solid lightgray; */
    padding: 8px 5px;
    border-radius: 5px;
    cursor: pointer;
}

.user-profile-accordion-small-screen {
    display: none;
}

.user-profile-tabs {
    background-color: #232c63;

}

.user-profile-tabs .nav-link {
    color: white;
}

.user-profile-tabs .nav-item.show .nav-link,15px
.user-profile-tabs .nav-link.active {
    background-color: #232c63;
    color: #dc7f92;
}

.animal-edit-icon {
    position: absolute;
background: linear-gradient(to right, #25BBE3, #C62EF8);
    color: white;
    bottom: 3px;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    left: -109px;
    right: 0px;
    width: 106px;
    height:48px;
    padding: 4px;

}

.user-profile-edit-icon {
background-color: #232c63f0;
border-radius: 5px;
bottom: -26px;
color: #fff;
cursor: pointer;
font-size: 14px;
left: 0;
position: absolute;
right: 0;
text-align: center;
width: 76px;
}
.user-profile-page-profile-img {
    position: relative;
}

.checkout-coupon-apply-div {
    background-color: darkblue;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.applied_coupon_div {
    background-color: #32cd3240;
}

.form-control-disabled {
    background-color: transparent !important;
}
.Form-height{
    height: 52px;
}

.checkout-policy-checkbox-div {
    font-size: 14px;
    color: gray;
    line-height: 1.8;
}

img.seller-register-logo {
    height: 85px;
    width: 85px;
    object-fit: contain;
    margin-top: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

svg.seller-register-logo-close {
    position: absolute;
    right: 5px;
    top: 2px;
    background-color: #232c63;
    color: white;
    border-radius: 50%;
    padding: 2px;
    font-size: 17px;
    cursor: pointer;
}

.seller-register-logo-div {
    height: 100px;
    width: 100px;
    position: relative;
    margin-top: 10px;
}


.best-seller-card {
    min-height: 386px;
}



/* .testimonial-card {
    border-radius: 10px;
    background-color: white;
    height: 346px;
    padding: 36px;
    width: 102%; 
  
    @media (max-width: 425px) {
      width: 100%; 
      padding: 30px; 
      height: 440px; 
    }
  } */
   

.testimonial-card { 
    border: 1px solid #ccc;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    max-width: 600px;
    margin: 20px auto;
}


.image-container {
    padding: 0;
}

.image-container img {
    width: 100%;
    height: 215px;
    display: block;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 14px;
}

.text-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* align-items: flex-start; */
}
.text-container .testimonial-description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;    
    line-height: 1.4;
     
    
}
.text-container h2 {
    margin: 0 0 10px 0;
    font-size: 1.5em;
}

.text-container p {
    margin: 0 0 10px 0;
    font-size: 16px;
    color: #070707;
}

.watch-video-btn {
    padding: 8px;
    /* padding: 10px 20px; */
    background-color: #232C63;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
}

.watch-video-btn:hover {
    background-color: #0056b3;
}

  
.address-container {
    margin-left: 60px; /* Set your desired margin-left value */
  }

  @media screen and (max-width: 767px) {
    .address-container {
      margin-left: 0; /* Set margin-left to 0 for screens <= 767px */
    }
  }
  @media screen and (min-width: 1500px) {
    .address-container {
      margin-left: 223px; /* Set margin-left to 70px for screens >= 1500px */
    }
  }

  .seller-profilr-button{
    padding-left: 404px;
    margin-top: -36px;
  }

  .file-input-wrapper {
    border: 2px dashed #a6aaad; /* blue dashed border */
    padding: 7px;
    text-align: center;
    width: 100%;
    border-radius: 7px;
}

.file-input {
    display: none; 
 }

.file-label {
    color: rgb(171, 179, 179); /* cyan color for label text */
    cursor: pointer;
    display: flex;
    align-items: center; /* align text and icon vertically */
    
}

.icon {
    font-size: 24px; /* bigger icon size */
    margin-right: 5px; /* add space between icon and text */
}

.browse-text {
    color: rgb(4, 7, 182); /* cyan color for browse text */
}

/* Style for when user hovers over the wrapper */
.file-input-wrapper:hover {
    border-color: #0056b3; /* darker blue on hover */
}


.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.3;
    text-align: justify;
  }
  
  .privacy-policy-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #232c63; /* Example color */
  }
  
  .privacy-policy-container h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #1d1d1d; /* Example color */
  }
  
  .privacy-policy-container p {
    margin-bottom: 15px;
    color: #333; /* Example color */
  }
  