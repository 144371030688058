.bloglist-body {
    background-color: #232c63;
    color: #dc79f2;
  }
  
  .h2-bloglist, .btn-primary-bloglist {
    background-color: #dc79f2;
    border-color: #dc79f2;
  }
  
  .btn-primary-bloglist:hover {
    background-color: #aa4db7; 
    border-color: #aa4db7;
  }
  
  .card-bloglist {
    background-color: #dc79f2;
    color: #232c63;
  }
  
  .card-bloglist:hover {
    background-color: #aa4db7; 
    color: #232c63;
  }


  .play-button{
    color: #fff;
    /* color: rgb(189, 20, 20); */
    left: 46%;
    top: 50px;
  }
  


  .faq-item {
    margin-bottom: 15px;
  }
  
  .faq-question {
    cursor: pointer;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
    border-radius: 10px;
  }
  
  .faq-question:hover {
    background-color: #e0e0e0;
  }
  
  .faq-answer {
    padding: 15px 0;
    /* margin-top: 5px; */
    /* background-color: #fff; */
    color: #4c4c4c;
    font-size: 18px;
  }
  
  .accordion-icon {
    font-size: 20px;
    margin-left: 10px;
  }
      