@media (max-width: 767px) {
  .scroll-right {
    overflow-x: scroll;
    direction: ltr;
  }
}

@media only screen and (max-width: 992px) {
  .small-screen-filter-modal {
    display: block !important;
    text-align: start;
  }

  .big-screen-filter-sidebar {
    display: none !important;
  }

  .consulation-request-form-row.row {
    top: 20rem !important;
  }

  .product-filter-submi-btn {
    display: block !important;
  }

  .footer-col-header {
    font-size: 17px !important;
  }

  .footer-col-content {
    font-size: 14px !important;
  }

  svg.footer-contactus-phone-icon {
    font-size: 16px !important;
    margin-right: 2px !important;
  }

  svg.footer-contactus-mail-icon {
    font-size: 18px !important;
    margin-right: 2px !important;
  }

  .footer-row-container.container {
    max-width: 95%;
  }

  .footer-social-icons-a {
    color: white;
    font-size: 25px;
  }

  ul.topnav-icons-ul {
    gap: 15px !important;
  }

  span.navbar-login-text {
    font-size: 12px !important;
  }

  .topnav-bs--icons-class {
    font-size: 20px !important;
  }

  .navbar-language-dropdowm-toggle {
    font-size: 13px !important;
  }

  h5.our-team-card-name {
    font-size: 18px !important;
  }

  .out-team-designation-cls {
    font-size: 14px !important;
  }

  .user-profile-accordion-small-screen{
    display: block !important;
  }
}

@media only screen and (max-width: 576px) {
  .product-filter-modal {
    width: 90%;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5%;
  }

  .consulation-request-form-row {
    margin-top: -15% !important;
  }

  .consult-services.row {
    margin-top: 110px;
  }

  img.home-page-marketplace-card-img {
    height: 40vh;
  }

  .out-team-card-img-cls {
    height: 65vh !important;
  }
}

@media only screen and (max-width: 768px) {
  .product-details-col2 {
    margin-top: 20px;
  }

  .consultation-request-form-col1 {
    display: none;
  }

  .consulation-request-form-row {
    margin-top: -100px;
  }

  .consulation-request-form-col2.row {
    margin-left: auto;
    margin-right: auto;
  }

  .footer-contactus-hr {
    width: 50% !important;
  }

  .footer-col-content {
    line-height: 2.4 !important;
  }

  .footer-logo-img {
    height: 65px !important;
  }

  .footer-logo-after-text {
    font-size: 20px !important;
  }

  .product-details-offer {
    left: 4% !important;
  }
}

@media only screen and (max-width: 1200px) {

  .home-banner-text1 {
    font-size: 38px !important;
    margin-bottom: 6vh !important;
  }

  .home-banner-text2 {
    font-size: 16px !important;
    line-height: 1.6 !important;
    margin-bottom: 4vh !important;
  }
}

@media only screen and (max-width: 480px) {
  .consulation-request-form-row {
    margin-top: -35% !important;
  }
}

@media only screen and (max-width: 390px) {
  .consult-services.row {
    margin-top: 53%;
  }

  p.footer-copyright-text {
    font-size: 10px !important;
  }

  .home-banner-content {
    margin-top: 15vh;
  }
}

@media only screen and (max-width: 350px) {
  .consult-services.row {
    margin-top: 66%;
  }
}

@media only screen and (max-width: 330px) {
  .consult-services.row {
    margin-top: 72%;
  }
}

@media only screen and (max-width: 640px) {
  .consulation-request-form-row {
    margin-top: -130px;
  }

  label.consult-form-label {
    padding-bottom: 2px;
  }

  button.consult-form-button {
    margin-top: 10px;
  }

  .consulation-request-form-col2.row {
    padding: 10px;
  }
}

@media (max-width: 767px) {

  .topnav-menu-small-screen-cls {
    display: none;
  }


  .topnav-menu-ul-cls {
    display: flex !important;
  }
}

@media (min-width: 1280px) {
  .cattlepagediv1 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}


@media (min-width: 768px) {
  .cattlepagediv1 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

}

@media (min-width: 1024px) {
  .cattlepagediv1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 16px;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 16px;
  }
}

@media screen and (min-width: 640px) {
  .chatbot {
    max-width: var(--chat-max-width);
    right: var(--chat-distance-to-window);
    top: auto;
  }
}


@media screen and (min-width: 640px) {
  .chatbot__message-window {
    height: var(--chat-height);
  }
}

@media only screen and (max-width: 840px) {
  .consulation-request-form-row.row {
    top: 15rem !important;
  }
}