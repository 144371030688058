/* .carousel-container {
    width: 80%; 
    margin: 0 auto;
    position: relative;
  }
  
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  
  
  .slide {
   
    
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
    
  }
  
  .slick-center .slide {
    transform: scale(1.1); 
    opacity: 1;
    height: 100% !important;
    width: 600px !important;           
  }
  
  .slick-prev, .slick-next {
    color: black;
    z-index: 1;  
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  .slick-slide:not(.slick-center) .slide {
    transform: scale(0.8);
    position: relative;
    z-index: -1; 

  }
  
  .slick-track {
    display: flex;
    align-items: center;
  }
  
 
  @media (max-width: 768px) {
    .slick-prev, .slick-next {
      display: none;
    }
  }
 */









  /* demo */

 /* Default inactive slide styles */
.demo .slick-slide {
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: scale(0.8);  /* Shrink inactive slides */
  opacity: 0.6;  /* Reduce opacity for inactive slides */
  z-index: 1;  /* Lower z-index for inactive slides */
}

/* Active slide styles */
.demo .slick-center {
  transform: scale(1.1);  /* Slightly scale up the active slide */
  opacity: 1;
  z-index: 3;
  transition: transform 0.5s ease, opacity 0.5s ease, z-index 0.5s ease;
}

/* Scale the image inside the active slide */
.demo .slick-center img {
  transform: scale(1.1);  /* Scale up image */
  object-fit: contain;  /* Ensure the full image is visible and maintains its aspect ratio */
  width: 100%;  /* Ensure image width stays consistent */
  height: 100%;  /* Allow image to fill the container */
  transition: transform 0.5s ease;  /* Smooth transition when scaling */
}

/* Ensure the container for the active image expands to accommodate the scaled image */
.demo .slick-center .blog-card {
  transform: scale(1.1);  /* Adjust container size to fit the image */
  transition: transform 0.5s ease;
}

/* Adjust other inactive slides */
.demo .slick-slide:not(.slick-center) {
  opacity: 1;
  transform: scale(0.8);  /* Shrink inactive slides */
  position: relative;
  z-index: -1;
}

/* Ensuring image aspect ratio and no cropping */
.demo .slick-center img {
  width: 100%;
  height: 22vw;
}

.demo .slick-slide:not(.slick-center) img {
  width: 100%;
  height: 22vw;
  object-fit: contain;
}






  