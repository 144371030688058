select {
  padding: 8px;
  /* margin-left: 8px; */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.shadow-0 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.border {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.rounded-3 {
  border-radius: 8px;
}

.bg-image {
  overflow: hidden;
}

.hover-overlay {
  position: relative;
}

.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hover-zoom:hover .mask {
  opacity: 1;
}

.text-danger {
  color: #dc3545;
}

.text-primary {
  color: #007bff;
}

.text-success {
  color: #28a745;
}

.text-hyperlink {
  color: #232C63;
}

.text-muted {
  color: #6c757d;
}

.btn-primary {
  background-color: #232C63;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary-seller-profile {
  background-color: #232C63;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: fit-content;
  margin-top: 12px;
}

.btn-primary:hover {
  background-color: #DC7F92;
}

.btn-outline-primary {
  color: #007bff;
  border: 1px solid #007bff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.btn-outline-primary:hover {
  color: #0056b3;
  border-color: #0056b3;
}


.play-overlay {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
}

/* 
.modal-dialog.login_modal {
  max-width: 900px !important;
  min-width: 900px !important;
} */

/* .login_modal {
  min-width: 700px;
  min-height: 600px;
  max-width: 700px;
} */

/* @media (max-width: 725px) {
  .login_modal {
    min-width: unset;
    width: 98%;
    margin: 0px;
  }
} */

.modal-content {
  background-clip: padding-box;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  color: var(--bs-modal-color);
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  width: 100%;
}

.modal-content {
  box-shadow: 0 0 1px 0 rgba(82, 100, 132, .2), 0 8px 15.52px 0.48px rgba(28, 43, 70, .15);
  min-height: 40px;
  position: relative;
  width: -webkit-fill-available;
}

.modal_close_icons {
  font-size: 30px;
  position: absolute;
  right: 0px;
  top: -8px;
  z-index: 10;
}

.modal_close_icons svg {
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 1px 5px #afafaf; */
  color: #000;
  cursor: pointer;
  padding: 6px;
}

/* .modal-content {
  padding: 5px;
} */

.login_forms,
.login_input_feilds {
  display: flex;
  flex-direction: column;
}

.login_forms {
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  width: fit-content;
}

.login_title {
  text-align: center;
  margin-bottom: 30px;
}

.login_title img {
  padding-bottom: 20px;
  width: 240px;
  margin-top: 22px;
}

img.login-modal-img {
  /* width: max-content;
  height: 350px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-modal-h5 {
  font-weight: 400;
  color: #232C63;
}
.button-profile {
  margin-right: -22px;
}
.login-modal-input::placeholder {
  font-size: 13px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.login-modal-btn .login-modal-submit-btn {
  border-radius: 3px !important;
  margin: 0 auto;
  padding: 10px 30px;
  width: fit-content;
  margin-bottom: 17px;
}

.login-otp-form {
  font-size: 19px;
  width: 45px;
  height: 50px;
}

@media (max-width: 375px) {
  .login-otp-form {
    font-size: 16px;
    width: auto;
    height: auto;
  }
}

.login-modal-btn {
  margin-top: 40px;
}

img.home-video-sec-img-cls {
  width: 100%;
  height: 258px;

}

.home-video-sec-title-clg {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  margin-bottom: 0.5rem;
  margin-top: 10px;
  text-align: left;
  height: 50px;
  color: #000;
  font-weight: 600;
}

.btm-footer-row {
  position: relative;
}

.btm-footer-img {
  position: absolute;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: contain;
}

.consultation-banner-image-opacity {
  opacity: 4;
}

.marketplace-container {
  margin-top: 30px;
  background-color: #E7E3E3;
}


.farm-title {
  color: #232c63;
}

.card h4 {
  text-align: center;
}

.card h5 {
  text-align: center;
  color: #dc7f92;
}

.card h6 {
  color: #dc7f92;
}

.row {
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  /* padding-left: calc(var(--bs-gutter-x)*.5); */
  padding-right: calc(var(--bs-gutter-x)*.5);
  width: 100%;
}

.Doctor-card {
  margin-top: var(--bs-gutter-y);
  width: 100%;
  padding-left: calc(var(--bs-gutter-x)*.0) !important;
  padding-right: calc(var(--bs-gutter-x)*.0) !important;
}

.custom-sidebar {
  background: #80808087;
  padding: 10px;
  border-radius: 5px;
}

.userTabItem {
  color: #7fa80e;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.custom-tab .nav-link {
  color: rgb(185, 73, 73);
}





.userTabItem:hover {
  background-color: #1e2266;
}

.logout_btn button {
  background-color: #c0392b;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.nav-item custom-tab {
  background: #000;
}

.userTabItem.btn {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.logout-btn {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.bordered-text-box {
  border: 1px solid #ccc;
  padding: 10px;
  height: auto;
  width: fit-content;
}

.small-image {
  width: 100px;
  height: auto;
}

.row.heading {
  margin-top: 10px;
}

.profile-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

input,
label {
  display: block;
}

.custom-input {
  padding: 10px;
  margin: 5px;
}


.product-image {
  width: 100%;
  height: auto;
  max-height: 180px;
  object-fit: cover;
}

.product-info {
  font-size: 14px;
}

.medium-text {
  font-size: 1.2rem;
}

.right-align {
  text-align: right;
}

.centered-label {
  display: block;
  text-align: center;
}

.blue-text {
  color: blue;
}

.small-icon {
  font-size: 0.8em;
}

.ribbon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: #f8e71c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.ribbon span {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.card-text-small {
  font-size: 12px;
}

.card-text-medium {
  font-size: 14px;
}

.green-text {
  color: green;
}

.description-text {
  max-height: 60px;
  overflow: hidden;
}


.larger-checkbox {
  transform: scale(1.5);
  margin-right: 10px;
}

.small-preview {
  max-width: 100px;
  max-height: 100px;
}

.custom-textarea {
  height: 150px;
  margin-top: 10px;
}

.file-input-wrapper {
  position: relative;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.browse-button {
  width: 100px;
  height: 40px;
  background-color: #f8f9fa;
  color: rgb(10, 10, 10);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Adjust other styles as needed */


.image-preview-container {
  margin-top: 10px;
  position: relative;
}

.image-preview {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* .clear-button {
  position: absolute;
  top: -13px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 16px;
} */
.custom-image-preview-container {
  position: relative;
}

.clear-button {
  position: absolute;
  top: -18px;
  right: -8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1;
}

.close-icon {
  display: inline-block;
  width: 24px;
  height: 26px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  background-color: #f5f5f8;
  color: #fff;
}

.close-icon svg {
  width: 30px;
  /* Adjust size as needed */
  height: 30px;
  /* Adjust size as needed */
  fill: fffff;
  /* Adjust color as needed */
}




.custom-image-preview-container {
  margin-left: 100px;
  position: relative;
}

.customFixedIconContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.customIconInner {
  cursor: pointer;
}

.customTopPadding {
  padding-top: 20px;
}

.error-message {
  color: red;
}


/* .farm-equi-card {
  height: 100%; 
} */

.card-body {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.right-section {
  width: 1440px;
  height: 347px;
  top: 1796px;
}

.white-background {
  background-color: white;
}

.individual-question {
  border-bottom: 1px solid #ccc;
}

.custom-container {
  width: 966px;
  height: 298px;
  border-radius: 20px;
  padding: 40px 100px;
  gap: 40px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.custom-content {
  width: 100%;
  height: 100%;
}

.custom-heading {
  color: rgba(35, 44, 99, 1);
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  padding-bottom: 2%;
  /* padding-top: 35px; */
}

.custom-paragraph {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center;
  width: 80%;
}

.custom-buttons {
  display: flex;
  justify-content: center;
}

.custom-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .custom-container {
    padding: 20px;
  }

  .custom-content {
    text-align: center;
  }
}

.call-button {
  background-color: white;
  color: rgba(35, 44, 99, 1);
  margin-right: 10px;
  border: 1px solid rgba(35, 44, 99, 1);
}


.explore-button {
  background-color: rgba(35, 44, 99, 1);
  color: white;
  margin-right: 10px;
}

.bg-light-subtle-gradient {
  background-image: linear-gradient(to bottom right, rgba(215, 220, 255, 1), rgba(235, 215, 255, 1)) !important
}

@media (max-width: 992px){
  .treatment-center-card p{
    font-size: 15px;
  }
}

@media (max-width: 768px){
  .treatment-center-card p{
    font-size: 13px;
    margin-top: 10px !important;
  }
}

@media (max-width: 368px){
  .treatment-center-card p{
    font-size: 10px;
    margin-top: 10px !important;
  }
}

.treatment-center-card {
  max-width: 80em;
  flex-direction: row;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
  /* margin: 3em auto; */
  height: 150px;
  margin-bottom: 66px;
}

@media screen and (min-width: 992px) {
  .treatment-center-card {
    max-width: 91em;
    flex-direction: row;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
    /* margin: 3em auto; */
    height: 252px;
  }
}

@media (max-width: 376px){
.itemcaro{
  margin-top: 92px !important;
}
}



@media screen and (min-width: 1024px) {
  .treatment-center-card {
    max-width: 91em;
    flex-direction: row;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
    /* margin: 5rem auto; */
    height: 257px;
  }
}

/* @media screen and (min-width: 1440px) {
  .treatment-center-card {
    max-width: 91em; 
    flex-direction: row;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
    margin: 8rem auto;
    height: 257px;
  }
} */
@media screen and (min-width: 1024px) {
  .team-card {
    max-width: 91em;
    flex-direction: row;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
    margin: 3em auto;
    /* height: 273px; */
  }
}

.team-card {
  max-width: 80em;
  flex-direction: row;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
  margin: 3em auto;
  height: 150px;
}

@media screen and (min-width: 992px) {
  .team-card {
    max-width: 91em;
    flex-direction: row;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
    margin: 3em auto;
    height: 252px;
  }
}

@media screen and (min-width: 1440px) {
  .treatment-center-card {
    max-width: 91em;
    flex-direction: row;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
    margin: 5em auto;
    height: 326px;
  }
}

@media screen and (min-width: 1440px) {
  .team-card {
    max-width: 91em;
    flex-direction: row;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
    margin: 3em auto;
    height: 326px;
  }
}


.custom-card-img-top {
  max-width: 20% !important;
  margin: auto;
  padding: 0.5em;
  border-radius: 0.7em;
}

.Team-custom-card-img-top {
  max-width: 35% !important;
  margin: auto;
  border-radius: 0.7em;
  position: absolute;
  bottom: 48px;
  right: 10px;
}

.custom-card {
  background-color: rgba(237, 237, 237, 1);

  height: 214px;
  width: 1, 100px;
}

.custom-card-treatment {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 300px; */
  padding: 2rem 0;
  border-radius: 25px;
}

.message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.message-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 10px;
  position: relative;
}

.customtreatment-heading {
  text-align: center;
  /* padding: 10px; */
  margin-bottom: 1.5rem;
  font-size: 22px;
  letter-spacing: 1px;
}

.circle-label {
  /* position: absolute !important; */
  /* bottom: -45px; */
  font-size: 16px;
  padding-top: 10px;
  font-family: 'NotoSans-Medium';
  text-align: center;
  margin-top: 1px;
}

.CommonDieasessexplore-button {
  background-color: #28a745;
  color: #fff;
}

.CommonDieasesscall-button {
  background-color: white;
  color: rgba(35, 44, 99, 1);
  /* margin-right: 10px; */
  border: 1px solid rgba(35, 44, 99, 1);
  padding: 10px 20px;

}


.CommonDieasessexplore-button {
  background-color: rgba(35, 44, 99, 1);
  color: white;
  margin-right: 10px;

}

.CommonDieasesscustom-buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
  margin-bottom: 10px;
}

.CommonDieasesscustom-buttons button {
  height: auto;

}

.CommonDieasesscustom-button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.apptext-center {
  text-align: center !important;
  display: flex;
  align-content: space-around;
  justify-content: flex-end;
}

.Custom-User-Icon {
  display: inline-flex;
  align-items: center;
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px;
}

.Custom-User-Icon .navbar-login-text {
  margin-left: 5px;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -30px;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 2rem;
  margin-left: 15%;
}

@media (max-width: 768px) {
  .testimonial-description {
    font-size: 18px;
    color: pink;
  }
}

/* @media (max-width: 992px) {
  .footer-contact-info {
    margin-top: 20px;
  }
} */

@media (max-width: 767px) {
  .mobile-app-container {
    display: none;
  }
}

.custom-margin-top {
  margin-top: 60px;
}

.footer-social-icon {
  font-size: 1.5rem;
}

.footer-social-icons-a {
  margin: 0 5px;
}

.price-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price-details p {
  margin-bottom: 5px;
}

.Subcall-button {
  background-color: white;
  color: rgba(35, 44, 99, 1);
  margin-right: 10px;
  border: 1px solid rgba(35, 44, 99, 1);
}

.Subexplore-button {
  background-color: rgba(35, 44, 99, 1);
  color: white;
  margin-right: 10px;
}

.subcustom-buttons {
  display: flex;
  justify-content: center;
  margin-right: 10px 10px;
}


/* styles.css */
.success-icon {
  font-size: 5em;
  color: rgba(0, 154, 0, 1);
}

.failed-icon {
  font-size: 5em;
  color: rgba(225, 0, 0, 1);
}

.success-message {
  color: rgba(0, 154, 0, 1);
  font-weight: bold;
  font-size: 1.5em;
}

.failed-message {
  color: rgba(225, 0, 0, 1);
  font-weight: bold;
  font-size: 1.5em;
}

.grey-text {
  color: grey;
}

.center-text {
  text-align: center;
}

@media (max-width: 576px) {
  .login-modal-img {
    display: none;
  }

  .login_forms {
    padding: 2rem 0;
  }

  .login_title img {
    width: 60%;
  }

  /* .login-modal-row {
    margin-bottom: 20px;
  } */

}


.custom-card {
  max-width: 100%;
  overflow-x: auto;
}

.circular-tab-container {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  /* display: flex;
  justify-content: space-between;
  position: relative; */
  /* padding: 5px 212px 0px; */
}

.message-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  margin: 0;
  /* margin-top: 10px; */
}

@media(max-width: 1023px){
.conn_container{
 margin-top: 67px !important;
}
}

.connector {
  width: calc(15% / 1);
  height: 1px;
  background-color: #BFBFBF;

  margin-top: 45px;
}

.connector-1 {
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.connector-2 {
  right: 50%;
  transform: translateX(50%) translateY(-50%);
}
/* 
.circle-label {
  pointer-events: none;
} */

@media only screen and (max-width: 767px) {
  .circular-tab-container {
    padding: 0px 0px 0px;
  }

  .message-circle {
    margin-bottom: 20px;
  }


  .connector {
    display: none;
  }
}

.user-icon-circle {
  border-radius: 50%;
  /* to make it circular */
  width: 50px;
  /* adjust the size as needed */
  height: 50px;
  /* adjust the size as needed */
  margin-right: 10px;
}

.user-details {
  margin-top: 2px;gap: 1408px;
}

.review-description {
  margin-top: 1px;
  font-size: 13px;
}

.image-link {
  display: inline-block;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

.image-link img {
  width: 22px;
  height: auto;
}


.background-custom-color {
  background-color: #FFD1DA;
  height: 35vh;
}

.our_team_banner_overlay{
  transform: translate(-50%, -50%);
}


.our_team_background{
  margin-bottom: 18vw;
}

@media(min-width: 1441px){
  .our_team_background{
    margin-bottom: 12rem;
  }
}


@media (max-width: 576px) {
  .background-custom-color {
    height: 27vh;
  }
}

@media (max-width: 991px){
.cow-medium-image{
display: none;
}
}

.Team-Icon-Tamil{
  width: 53px;
  height: 30px;
  background-color: #fff;
  border-radius: 20px;
  margin-right: 6px;
}

.Team-Icon-Marati{
  width: 46px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 2px;
}

.Team-Icon {
  width: 32px;
  height: 30px;
  background-color: #fff;
  border-radius: 50px;
  margin-right: 9px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.pink-designation {   
  /* font-weight: 600; */
  font-size: 14px;
  color: #f5f5f8;
  line-height: 10px;
}

.Core-blue-background {
  background-color: #4E548D;
  border-radius: 10px;
  height: 100px;
  padding: 20px;
  color: white;
  text-align: center;
  font-size: 18px;
}

/* .related-products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} */

.related-products-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Create four equal columns */
    /* gap: 16px;  */
}
.related-product-card {
  /* background-color: #f9f9f9; */
  padding: 8px;
  text-align: center;
  border-radius: 8px;
}
.core-team-blue-background {
  background-color: #007bff;
  padding: 8px;
  color: white;
}

.Core-Team-common-disease-img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* .related-product-card {
  width: calc(25% - 10px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
} */

.related-product-card img {
  width: 100%;
  height: auto;
  border-radius: 21px 21px 0px 0px;
}

/* .blue-background {
  background-color: #4E548D;
  color: white;
  padding: 10px;
} */
.unique-team-name {
  font-size: 18px;
  color: white;
  /* Add any other unique styles here */
}
@media (max-width: 1024px) {
  .related-products-container {
      grid-template-columns: repeat(3, 1fr); /* Three columns on tablets */
  }
}

@media (max-width: 768px) {
  .related-products-container {
      grid-template-columns: repeat(2, 1fr); /* Two columns on smaller tablets and large phones */
  }
}

@media (max-width: 480px) {
  .related-products-container {
      grid-template-columns: 1fr; /* One column on small mobile devices */
  }
}



@media (max-width: 480px) {
  .unique-team-name {
    font-size: 12px;
    /* Decrease font size for screens below 480 pixels wide */
  }
}

@media (max-width: 480px) {
  .pink-designation {
    font-size: 11px;
    color: #DC7F92;
  }
}

.blue-background {
  background-color: #4E548D;
  border-radius: 0px 0px 10px 10px;
  height: 80px;
  /* height: 112px; */
  padding: 17px 18px ;
  color: white;
  text-align: center;
  font-size: 17px;
}

.core-team-blue-background {
  background-color: #4E548D;
  border-radius: 0px 0px 10px 10px;
  height: 100px;
  padding: 25px;
  color: white;
  text-align: center;
  font-size: 17px;
}
.core-team-blue-background-2 {
  background-color: #4E548D;
  border-radius: 0px 0px 10px 10px;
  height: 127px;
  /* padding: 14px; */
  padding-top: 25px;
  color: white;
  text-align: center;
  font-size: 17px;
}

.pink-designation {
  margin-top: 5px;
  line-height: 15px;
}

/* @media screen and (max-width: 768px) {
  .related-product-card {
    width: calc(50% - 10px);
  }
} */


.Doctor-img-fluid {
  height: 447px;
  width: 100%;
  border-radius: 6%;
}

.Doctor-2-img {
  height: 212px;
  width: 202%;
  margin-bottom: 20px;
  border-radius: 6%;
  position: relative;
  margin-left: 21px;
}

/* .Last-Doctor-Grid {
  height: 218px;
  width: 100%;
  border-radius: 6%;
  position: relative;
  margin-left: 21px;
} */
.Last-Doctor-Grid {
  width: 100%;  /* Ensures the image takes the full width of its container */
  height: 218px;  /* Maintains aspect ratio */
  object-fit: cover;  /* Ensures the image covers the container without distortion */
  max-width: 258px; /* Adjust the max-width as needed */
  max-height: 218px; /* Adjust the max-height as needed */
  display: block;  /* Removes any default inline-block spacing */
  border-radius: 6%;
  position: relative;
  margin: 0 auto;  /* Centers the image within its container */
}
@media (max-width: 767px) {
  .Last-Doctor-Grid {
    max-width: 100%; 
    height: auto; 
    display: block; 
    margin: 10px auto; 
  }
}

.positioned-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-84%, -50%);
  color: #ffff;
}

.new_home_page-content {   
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-141%, -181%);
  color: #ffff;
}

@media (max-width: 1023px) {
  .new_home_page-content {
    transform: translate(-100%, -181%);
  }
}


.new_home_page-content-medicine_section {   
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(25%, -209%);
  color: #232C63;
}

@media (max-width: 1024px) {
  .new_home_page-content-medicine_section {
    transform: translate(0%, -149%);
  }
}


/* .custom-position-relative{
   display: flex;
    flex-direction: column;
    align-items: center;
} */
.veterinary-service-container {
  text-align: center;
  padding: 20px;
}

.feeding_full{
  background: none;
  opacity: initial;
  padding: 0px;
}

.feeding_management_carousel{
  
}

.banner-img-fluid {
  width: 100%;
  height: auto;
}

.cars_head_1{
  font-size: clamp(1rem, 3vw, 2rem);
}

.cars_para_1{
  
    font-size: clamp(0.875rem, 1vw, 1.25rem);
  
  
}

.cars_para_3{
  font-size: clamp(0.7rem, 1vw, 1rem);
}

.new_car{
  background: linear-gradient(94.66deg, #081271 46.3%, #FFB9C7 90.24%);
}

.new_car1{
  background: #00063F;
}

.new_car2{
  background: #262C63;
}

/* .text-container {
  margin-top: 20px;
} */

.button-container {
  margin-top: 20px;
}

.team-center-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.team-center-card-button:hover {
  background-color: #0056b3;
}

.team-icon {
  margin-right: 10px;
}

.second-positioned-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-84%, -50%);
  color: #121856;
}

.treatment-heading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-84%, -50%);
  color: #DC7F92;
}

.white-text {
  color: white;
}


@media only screen and (max-width: 768px) {
  .white-text {
    color: white;
  font-size: 11px;
  }
}

@media only screen and (max-width: 525px) {
  .white-text {
    color: white;
  font-size: 7px;
  }
}
.phone-icon {
  position: absolute;
  left: 10px;
  /* Adjust as needed */
  top: 50%;
  transform: translateY(-0%);
  background-color: #fde7ea;
  /* Light pink color */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* Circle */
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-icon svg {
  color: #dc7f92;
}

@media screen and (max-width: 991px) {
  .responsive-text {
    font-size: 8px;
  }
}
.root-container{
  width: 98%;
  /* width: 1300px; */
  margin-right: -10px;
}
@media (min-width: 1440px) {
  .root-container {
    width: 96%;
    margin-right: 0; /* Adjust if needed to align properly */
  }
}
@media (min-width: 1990px) {
  .root-container {
    width: 98%;
    margin-right: 0; /* Adjust if needed to align properly */
  }
}

.team-final{
  display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
}


.cow-count-container {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  border: 1px solid #b1b1b1;
  border-radius: 3px;

}

.form-label-Animal-custom-style {
  flex: 1; 
  padding-left:10px;
  /* text-align: right; */
  margin-right: 10px; 
  line-height: normal;
}

.farm-input-box {
  flex: 1; /* Takes up half the space */
}

.sample-image {
  width: 100%;
  padding-top: 30%; 
  position: relative;
}

.sample-img {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%; */
  height: 110px;
  width: 110px;
  object-fit: cover;
}



.sample-video{
  width:100px;
  height: 100px;
  position: absolute;
  top:0;
  object-fit: cover;
}

.custom-color{
  color: #DC7F92;

}
.new_home_page_custom-color{
  color: #232c63 !important;
}
.footer-section {
  color: white;
  background-color: #232C63;
  height: 378px;
}

@media (max-width: 767px) {
  .footer-section {
      height: 799px; /* Adjust this value as needed */
  }
}

@media (max-width: 324px) {
  .footer-section {
      height: 1033px !important; /* Adjust this value as needed */
  }
}

@media (min-width: 244px) and (max-width: 280px) {
  .footer-section {
      height: 1164px !important; /* Adjust this value as needed */
  }
}


@media (max-width: 668px) {
  .footer-section {
      height: 1102px; /* Adjust this value as needed */
  }
}


.message-image{
  height: fit-content;
  width: 130%;
}

.blog-card-section{
  display: flex;
  flex-direction: row;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  height: 148px;
}