
  
 .container-for-bestseller {
    position: relative;
    margin: 70px auto;
    text-align: center;
    background: #fff !important;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1), 0px -10px 25px rgba(0, 0, 0, 0.1), 0px 60px 75px -25px rgba(0, 0, 0, 0.2);
    cursor: default;
  }
  
  .img-fruit {
    width: 200px;
    height: 200px;
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    border: 2px solid lightgray;
    border-radius: 10px;
}
  .container-for-bestseller div.control-btn span {
    position: absolute;
    margin-top: 20px;
    width: 35px;
    height: 35px;
    background: #dc7f92;
    color: #fff;
    font-size: 13px;
    display: inline-block;
    line-height: 36px;
    cursor: pointer;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    -moz-box-shadow: 0px 2px 7px rgba(241, 44, 51, 0.7);
    -webkit-box-shadow: 0px 2px 7px rgba(241, 44, 51, 0.7);
    box-shadow: 0px 2px 7px rgba(241, 44, 51, 0.7);
    -moz-transition: background 0.3s ease-in;
    -o-transition: background 0.3s ease-in;
    -webkit-transition: background 0.3s ease-in;
    transition: background 0.3s ease-in;
  }
 
  .container-for-bestseller span.prev {
    left: -17.5px;
  }
  .container-for-bestseller span.next {
    right: -17.5px;
  }
  .container-for-bestseller h3 {
    font-size: 23px;
    letter-spacing: 3px;
    margin-top: 25px;
    color: #232c63;
}
  .container-for-bestseller p {
    font-size: 16px;
    padding: 10px 30px 0px 30px;
    color: #878787;
}
  .container-for-bestseller div.properties {
    margin-top: 20px;
    height: 130px;
  }
  .container-for-bestseller div.properties:before, .container-for-bestseller div.properties:after {
    content: " ";
    display: inline-block;
  }
  .container-for-bestseller div.properties:after {
    clear: both;
  }
  .container-for-bestseller div.properties div {
    display: inline-block;
    text-align: center;
    width: 33.33%;
    /* float: left; */
  }
  .container-for-bestseller div.properties div h4 {
    font-size: 14px;
    color: #757575;
    padding: 25px 0px;
  }
  .container-for-bestseller div.properties .qty .fa {
    font-size: 7px;
    color: #757575;
    background: #dfdfdf;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    padding: 4px;
    cursor: pointer;
  }
  .container-for-bestseller div.properties .qty .number {
    font-size: 18px;
    font-weight: 500;
    color: #757575;
    padding: 5px;
  }
  .container-for-bestseller div.properties .price .fa {
    font-size: 18px;
    color: #757575;
  }
  .container-for-bestseller div.properties .price .amount {
    color: #13d72aad;
    font-size: 18px;
    padding: 0 5px;
  }
  .container-for-bestseller div.properties .delivery .fa {
    font-size: 15px;
    color: #757575;
  }
  .container-for-bestseller div.properties .delivery .time {
    display: inline-block;
    color: #757575;
    font-size: 11px;
  }
  .container-for-bestseller .ip-add-cart {
    border: none;
    margin-top: 30px;
    padding: 12px 50px;
    background: #232c63;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    border-radius: 25px;
    -moz-box-shadow: 0px 15px 45px rgba(242, 45, 52, 0.5);
    -webkit-box-shadow: 0px 15px 45px rgba(242, 45, 52, 0.5);
    box-shadow: 0px 15px 45px rgba(242, 45, 52, 0.5);
    cursor: pointer;
    -moz-transition: background 0.3s ease-in;
    -o-transition: background 0.3s ease-in;
    -webkit-transition: background 0.3s ease-in;
    transition: background 0.3s ease-in;
    margin-left: auto;
    margin-right: auto;
  }
  .container-for-bestseller .ip-add-cart:hover {
    background: #dc7f92;
  }
  
  div.credit {
    position: absolute;
    padding: 0px 10px;
    padding-bottom: 8px;
    height: 15px;
    right: 10px;
    top: 10px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.7);
    font-size: 7px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 15px -15px rgba(0, 0, 0, 0.3);
    cursor: default;
  }
  div.credit:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  div.credit img {
    position: relative;
    width: 14px;
    margin-left: 3px;
    top: 4px;
  }
  
  
  
.slick-slide > div{
    width: 95%;
  }
 
  