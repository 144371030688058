.checkout-stepper-main-div {
    display: flex;
    gap: 1rem;
}

.checkout-stepper-vertical-line {
    width: 12rem;
    height: 1.2px;
    background-color: #232c63;
}

.checkout-stepper-map-div:last-child .checkout-stepper-vertical-line {
    width: 0;
    height: 0;
}

.checkout-stepper-map-div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.checkout-stepper {
    display: flex;
    justify-content: center;
}

.checkout-step {
    text-align: center;
}

.checkout-steop-index-value {
    border: 1px solid #232c63;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    color: #232c63;
    font-size: 16px;
    align-items: center;
}

.checkout-step-index {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.checkout-step-active {
    background-color: #232c63;
    color: #fff;
}

.checout-stepper-label {
    font-size: 14px;
}