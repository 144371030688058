

  .tabs:hover{
    border-bottom: 4px solid #dc7f92;
    cursor: pointer;
    transition: 'transform 0.3s ease-in-out ';
  
  }


  .category-image{
    width: 40px;
    height: 40px;
    border-radius: 30%;
  }

  