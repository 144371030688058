header .search-box {
  position: relative;
}

header ul {
  flex: 2;
  display: flex;
  justify-content: space-evenly;
}

header ul li {
  list-style: none;
}

header ul li a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 15px;
  cursor: pointer;
}

header ul li a:hover {
  border-bottom: 2px solid #dc7f92;

}
.nav-tabs .nav-link {
  margin-bottom: none;
}

header .menu {
  font-size: 1.5em;
  display: none;
}

/* @media(max-width:576px) {
  .search-box button {
    position: absolute;
  }

  header img {
    height: 2.5rem;
  }

  header ul {
    position: absolute;
    top: 70px;
    left: -100%;
    background: #dc7f92;
    height: 100%;
    width: 60%;
    flex-direction: column;
    align-items: center;
    transition: right 0.5s linear;
  }




  header .menu {
    display: block;
    width: 100px;
    text-align: center;
  }

  #chk1:checked~ul {
    left: 0;
  }

} */

.dropdown1 {
  position: relative;
  display: inline-block;
}

.dropdownlist {
  color: #fff;
  font-weight: 500;
  padding: 10px 15px;
  position: relative;
  margin-right: 20px;
  list-style: none;
}

ul.dropdownul {
  padding-left: 0;
  position: absolute;
  top: 27%;
  left: 18%;
  background-color: #232c63;
  border-top: 2px solid #ffffffc9;
  padding: 10px 20px 10px 0;
}

.topnav-menu1-main-cls {
  background: #232c63;
}

img.topnav-menu1-logo-img {
  width: 100%;
  height: 55px;
  object-fit: contain;
  margin-left: 57px;
}
@media screen and (max-width: 1023px) {
img.topnav-menu1-logo-img {
  margin-left: 0; 
}
}
.search-box button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  border: none;
  position: absolute;
  top: 22px;
  transform: scale(0.9);
  background: #39469f;
  color: #fff;
}

.topnav-menu1-row-cls {
  height: 65px;
  padding-left: 0;
  padding-right: 0;
}

.topnav-menu2-row-cls {
  padding-left: 0;
  padding-right: 0;
}

.topnav-menu2-col-cls {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding-left: 0;
  padding-right: 0;
}

.topnav-menu2-link-div {
  height: 65px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  /* padding: 10px 15px; */
  cursor: pointer;

}

.topnav-menu-dropdown-toggle {
  padding: 0;
}

.topnav-menu2-link-div a:hover {
  border-bottom: 2px solid #dc7f92;
}

.topnav-active-menu-link {
  border-bottom: 2px solid #dc7f92;
}

.dropdown-content-products {
  left: 10%;
}

.dropdown-content-services {
  left: 29%;
}

.dropdown-content-marketplace {
  left: 37%;
}

.dropdown-content-KnowledgeBase {
  left: 60%;
}

.dropdown-content-aboutus {
  right: 5%;
}

.dropdown-content-div-cls {
  display: none;
  position: absolute;
  top: 161px;
  background-color: #232c63;
  min-width: 150px;
  border: 1px dashed white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 14px;
  z-index: 1;
  border-radius: 10px;
}

.dropdown-content-div-cls ul {
  list-style: none;
  display: flex;
  margin-bottom: 0;
}

.topnav-menu2-link-div:hover .dropdown-content-div-cls-ss {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

.dropdown-content-hr {
  position: relative;
  height: 30px;
  margin: 0 10px;
  border: 0;
  border-left: 3px solid #fff;
  margin-top: auto;
  margin-bottom: auto;
}

svg.topnav-ss-menu-icon {
  color: white;
  font-size: 32px;
}

.topnav-small-screen-cls {
  display: none !important;
}

.topnav-hambarger-menu-list {
  display: inline;
}

.topnav-ul-mobile {
  font-weight: 600;
  margin-bottom: 0 !important;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-shadow: 5px 0 5px -2px #88888882;
  z-index: 9;
  background-color: #232c63;
}

.topnav-ul-mobile div {
  list-style: none;
  line-height: 2.5;
}

.topnav-bs--icons-class {
  color: rgb(0, 0, 0);
  font-size: 23px;
}

svg.topnav-down-arrow {
  font-size: 18px;
  margin-bottom: 4px;
}

.topnav-menu-dropdown-menu.dropdown-menu.show {
  margin-top: 2.2vh;
  border-radius: 0;
  background-color: #232c63;
  border-top: 2px solid #fff;
  padding: 10px 35px 10px 0;
  line-height: 2.3;
  font-size: 15px;
}

.topnav-menu-dropdown.show.dropdown {
  display: flex;
  align-items: center;
}

.topnav-menu-dropdown.dropdown {
  display: flex;
  align-items: center;
}

.topnav-menu-dropdown-menu .dropdown-item {
  transition: transform 0.3s ease-in-out;
}

.topnav-menu-dropdown-menu .dropdown-item:focus,
.topnav-menu-dropdown-menu .dropdown-item:hover {
  color: #dc7f92 !important;
  background-color: transparent;
  transform: scale(1.1);
}

.offcanvas {
  color: #fff !important;
  background-color: #232c63 !important;
}

.custom-offcanvas-header .btn-close {
  background-color: white;
}

ul.navbar-offcanvas-ul {
  list-style: circle;
  line-height: 2;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 300;
}

@media only screen and (max-width: 992px) {

  .topnav-menu2-row-cls,
  .topnav-menu-dropdown-toggle,
  .topnav-menu-dropdown-menu .dropdown-item {
    font-size: 14px;
  }


  svg.topnav-ss-menu-icon {
    color: white;
    font-size: 32px;
  }

  .topnav-menu-dropdown-menu.dropdown-menu.show {
    line-height: 2;
  }
}

@media only screen and (max-width: 325px){
  svg.topnav-ss-menu-icon{
    color: white;
    font-size: 52px;
  }
}

@media only screen and (max-width: 992px) {
  .topnav-menu2-container-cls {
    display: none;

  }

  .topnav-big-screen-cls {
    display: none !important;
  }

  .topnav-small-screen-cls {
    display: flex !important;
    /* padding-left: 0;
    padding-right: 0; */

  }

  .search-box input {
    height: 34px;
    font-size: 12px;
  }

  .search-box button {
    width: 34px;
    height: 34px;
    top: 18px;
  }

  .topnav-ss-right-icons {
    font-size: 22px;
  }

  .topnav-menu2-col-cls {
    flex-direction: column;
    justify-content: normal;
  }

  svg.topnav-ss-menu-close-icon {
    margin: 20px 0 0 15px;
  }

  .dropdown-content-div-cls ul {
    display: block;
  }

  .topnav-menu2-link-div {
    display: block;
  }

  .dropdown-content-div-cls {
    position: static;
  }

  .topnav-menu2-link-div {
    height: auto;
  }

  ul.topnav-icons-ul {
    gap: 30px !important;
  }

  .topnav-bs--icons-class {
    font-size: 25px !important;
  }

  .cart-count-badge {
    top: 7px;
    margin-left: 17px;
  }

  svg.navbar-search-icon {
    top: 62px;
    left: 20px;
    margin-top: 0;
    margin-left: 0;
  }

  .navbar-language-dropdowm-toggle {
    font-size: 16px !important;
  }

  .topnav-mobile-col1 {
    gap: 10px;
  }

  .topnavbar-mobile-icon-column {
    gap: 1.5rem;
    align-items: center;
  }

  img.topnav-menu1-logo-img {
    height: 60px;
  }

}

@media only screen and (max-width: 576px) {
  img.topnav-menu1-logo-img {
    height: 50px;
  }

  .search-box input {
    height: 30px;
    font-size: 10px;
    padding-right: 0;
  }

  .search-box button {
    width: 30px;
    height: 30px;
    top: 15px;
  }

  .topnav-ss-right-icons {
    font-size: 18px;
  }

  .topnav-ul-mobile div {
    font-size: 14px;
    line-height: 2;
  }

}

@media only screen and (max-width: 575px) {
  ul.topnav-icons-ul {
    gap: 20px !important;
  }

  .topnavbar-mobile-icon-column {
    gap: 1rem;
  }

  /* svg.topnav-ss-menu-icon {
    font-size: 25px;
  } */

  /* .topnav-bs--icons-class {
    font-size: 20px !important;
  } */

  .cart-count-badge {
    height: 17px;
    width: 17px;
    line-height: 17px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 391px) {
  .search-box input {
    height: 25px;
    padding-left: 22px;
  }

  .search-box button {
    width: 25px;
    height: 25px;
    top: 17px;
  }

  .topnav-ss-right-icons {
    font-size: 16px;
  }

  .topnav-ul-mobile div {
    font-size: 12px;
  }

  ul.topnav-icons-ul {
    gap: 10px !important;
  }
}

.navbar-language-dropdowm .navbar-language-dropdowm-toggle {
  color: #f3f3f3 !important;
}