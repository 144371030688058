/* slick-carousel styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


*{
  /* font-family: 'poppins', sans-serif; */
  font-family: 'NotoSans', sans-serif;
}

body{
  padding:0;
  margin:0;
 background-color:#fff !important;
 /* background-color:#F3F3F3 !important; */
 /* background-color:whitesmoke; */
}


a {
  color: white !important;
  text-decoration: none !important;
}



.h1heading{
color: #232c63;
font-size: 29px; 
text-align: center; 
}
.icons:hover{
  color: #dc7f92  !important;
}


.card:hover {
transform: scale(1.015);
}

.pagination {
margin-top: 20px;
}

.text-center h2 {
color: #232c63;
}

.btn {
background-color: #232c63 !important;
color: white !important;
font-weight: 600;
padding: 10px;
}

.btn:hover{
  color: black;
}

@media (max-width: 576px) {
.mb-4 {
  margin-bottom: 1rem;
}
}


.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}
.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}
.testimonial .slick-arrow.slick-next {
  right: -70px;
}
.testimonial .slick-arrow.slick-prev {
  left: -70px;
}
.testimonial .slick-dots li button:before {
  font-size: 15px;
}
.testimonial .slick-dots li {
  top: 15px;
}


/* .slick-slide > div{
  margin: 0 30px;
} */

.custom-prev-arrow,
.custom-next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.custom-prev-arrow {
  left: 20px;
}

.custom-next-arrow {
  right: 20px;
}






.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  border-bottom: 1px solid #ccc;
}

.vetnary
{
  color:white;
  -webkit-text-fill-color: #232c63; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #dc7f9280;
}

.vetnarytext
{
  color:white;
  
  -webkit-text-fill-color: whitesmoke; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(164, 162, 162);
}
/* Logo styles */
/* .col-md-4 img {
  height: 35px; 
} */

/* Search form styles */
.form-control {
  border-radius: 20px; /* Adjust as needed */
}

/* Cart and other icons styles */
.text-reset {
  color: #000; /* Adjust as needed */
  text-decoration: none;
  display: flex;
  align-items: center;
}






.rounded-circle {
  border-radius: 50%;
  margin-right: 5px;
}

.navbar {
  background-color: #fff;
  padding: 10px 0;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

/* Sidenav styles */
.sidenav {
  width: 250px;
  position: fixed;
  top: 0;
  left: -250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: left 0.3s ease;
}





/* Hover overlay styles for product images */
.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Responsive styles */
@media (max-width: 768px) {
  /* .col-md-4 {
    text-align: center;
  } */

  .justify-content-md-end {
    justify-content: center;
  }
}

   
@media (min-width: 768px) {
  /* .col-md-4 {
    text-align: center;
  } */

  .footer-logo-container {
    width: 50%;
  }
}
@media (min-width: 768px) {
  /* .col-md-4 {
    text-align: center;
  } */

  .footer-logo-img {
    width: 161px;
  }
}

.containerMarketPlace {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #333;
  color: #fff;
  padding: 15px;
}

.content {
  flex: 1;
  padding: 10px;
}

.tab-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tab-list-item {
  margin-bottom: 10px;
}

.tab-list-item a {
  text-decoration: none;
  color: #fff;
  display: block;
  padding: 10px;
  background-color: #555;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.tab-list-item a:hover {
  background-color: #777;
}





.video-link {
  display:block; 
  border:2px solid #dc7f92;
  margin: 10px auto;
  overflow:visible;
  position:relative;
  cursor: pointer;
}

.video-link .thumbnail {
  width:100%;
  object-fit: contain;
  object-position: center;
}

.video-link::after {
  content:"";
  display:block;
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  background-image:url("//cyara.com/wp-content/uploads/resources/play-button.png");
  background-position: center center;
  background-repeat:no-repeat;
  background-size:20%;
  filter: drop-shadow(0 0 10px rgba(0,0,0,.4));
}

.video-link:hover::after {
  background-size:22%;
  -webkit-transition: background-size .2s ease-in-out;
  -moz-transition: background-size .2s ease-in-out;
  -o-transition: background-size .2s ease-in-out;
  transition: background-size .2s ease-in-out;
}


#carouselExampleControls {
  max-width: 100%;
  width: 90%;
  height: 400px;
}

.carousel-caption h5,
.carousel-caption p {
  color: black;
  font-weight: bold;
}




.carousel-control-prev,
.carousel-control-next {
  width: fit-content; 
  height: 40px; 
  color: #232c63;
  border-radius: 50%; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #992b2b;
  border-radius: 50%;
  width: 20%; 
  height: 20%; 
}


.carousel-wrapper .carousel-control-next {
  top: 80px;
  
}

.carousel-wrapper .carousel-control-prev{
  top: 80px;
}



.animal-individual-image{
width: 80vh;
max-height: 28vh;
}



.ratings{
  margin-right:10px;
}

.ratings i{
  
  color:#cecece;
  font-size:25px;
}

.rating-color{
  color:#fbc634 !important;
}

.review-stat{
  font-weight:300;
  font-size:18px;
  margin-bottom:2px;
}


.seller-card {
  background-color: #f8f9fa;
  border: 1px solid #182735;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.seller-tabs-container {
  margin-top: 20px;
}

.seller-tabs {
  background-color: #dc7f92;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.seller-tabs a {
  color: #232c63 !important;
  font-weight: bold;
}

.seller-tabs a:hover {
  color: #232c63 !important;
} 


.seller-cattle-image,.seller-card-title,.seller-card-text{
  color: #232c63 !important;
  margin-left: auto;
  margin-right: auto;
}

.seller-cattle-image{
  width: 150px;
  height: 150px !important;
}


.custom-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-form label {
  font-weight: bold;
  color: #495057;
}

.custom-form input {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.custom-form input:focus {
  border-color: #007bff;
  outline: 0;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.25);
}

.custom-form .img-thumbnail {
  border: none;
}

.custom-form .form-control-file {
  display: none;
}

.custom-form .btn {
  background-color: #007bff;
  color: #ffffff;
  border: none;
}

.custom-form .btn:hover {
  background-color: #0056b3;
}

