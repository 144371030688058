.img-animals{
    width: 20vh;
    height: 15vh;
}

.animals-div{
    cursor: pointer;

}

.card-animals{
    border: 1px solid #232c63;
    border-radius: 5px;
    margin-right: 100px;
    margin-bottom: 5vh;
    margin-top: 20px;
}
