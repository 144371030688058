.marketplaceimg {
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  height: 8rem;
  width: 9rem;
  padding: 0.25rem;
  background-color: #8e9b9c;
  border-radius: 0.375rem;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.3s;
  transform: scale(1.05);
}

.marketplaceimg:hover {

  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.3s;
  transform: scale(1.05);
}

.cattlepageimg {

  object-fit: cover;
  width: 100%;
  height: 12rem;
  margin-bottom: 0.5rem;
  border-radius: 0.375rem;
  background-color: #8e9b9c;
}

.cattlepagediv {
  border-width: 1px;
  border-style: solid;
  padding: 1rem;
  border-radius: 0.375rem;
  transition-property: transform;
  transform: scale(1.05);
}

.cattlepageh2 {

  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}


.cattlepageptag {
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.cattlepagepspan {
  color: #84cc16;
  font-weight: 700;
}

.cattlepagediv1 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 768px) {
  .cattlepagediv1 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .cattlepagediv1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .cattlepagediv1 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.cattlepageulclass {
  margin-top: 1rem;
  justify-content: center;
  display: flex;
}

.blogfirstdiv {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.blogseconddiv {
  border: 1px solid #bfbfbf;
  border-radius: 0.375rem;
  /* border-style: solid;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(220 127 146 / var(--tw-border-opacity));
  padding: 1rem;
  border-radius: 0.375rem;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); */

}

.blogpageh1 {
  --tw-text-opacity: 1;
  color: rgb(35 44 99 / var(--tw-text-opacity));
  text-align: center;

}

.blogsliderclass {
  margin-top: 1.25rem;
} 


.blogpageimg {
  /* object-fit: cover; */
  width: 350px;
  /* width: 350px; */
  /* height: 258px; */
  margin-bottom: 0.5rem;
  border-radius: 10px;
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust based on your layout */
}



.videopageseconddiv {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.videopageh1 {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(35 44 99 / var(--tw-text-opacity));
}

.videopagefifthdiv {
  border: 1px solid #bfbfbf;
  border-radius: 20px;
  /* border-style: solid;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: #232c63;
  padding: 1rem;
  border-radius: 0.375rem;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer; */

}

.videopageh2tag {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;

}

.footerlastimagediv {
  position: relative;
  z-index: 1;
  margin-top: 120px;
  text-align: center;
  color: white;

}

.footerlastimageptag {
  position: relative;
  bottom: 120px;
  color: '#232C63';
  font-weight: '600';
  font-size: '18px';
  color: #000;
}

@media (max-width: 1000px) {
  .footerlastimageptag {
    color: #fff;
  }
}


@media (min-width: 1750px) {
  .btm-footer-row {
    margin-top: 50px !important;
    max-width: 100% !important;
  }
}

.footerlastimagehrtag {
  position: relative;
  border: '2px solid black';
  width: '50%';
  bottom: 100px;
}

.btm-footer-row {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
}

.btm-footer-img {
  max-width: 100%;
  height: auto;
}

.countdiv {
  background-color: '#E7E3E3';
  text-align: 'center';
  padding: '20px';
  justify-content: space-around;
  padding-top: '80px';

}

.category-card {
  background-color: #ececec;
  border-radius: 8px;
  box-shadow: inset;
  width: 100%;
  height: 140px;
}

.category-text {
  position: relative;
  top: 10px;
}

.category-card-img {
  position: relative;
  top: 20px;
}

.Animal-image {
  width: 150px;
  height: 150px;
}

.Homepageh1 {
  color: #232323 !important;
}

.viewall {
  color: #232c63 !important;
  font-size: 20px;
}

.center-card {
  max-width: 80em;
  flex-direction: row;
  background: rgb(161, 143, 191) !important;
  background: linear-gradient(180deg, rgba(161, 143, 191, 0.9624182436646533) -14%) !important;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
  margin: 3em auto;
}

.center-card.dark {
  color: #fff;
}

.card.card.bg-light-subtle .card-title {
  color: dimgrey;
}

.center-card img {
  max-width: 20% !important;
  margin: auto;
  padding: 0.5em;
  border-radius: 0.7em;
}

.center-card-body {
  display: flex;
  /* max-width: 1000px; */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* flex-wrap: wrap; */
  /* padding: 3rem; */
  /* height: 100px; */
}

.center-card-body2 {
  display: flex;
  max-width: 1000px;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* flex-wrap: wrap; */
  /* padding: 3rem; */
  /* height: 100px; */
}

/* .custom-slick-margin{
  margin-bottom: 30px;
} */

@media(max-width: 376px){
  .center-card-body{
    height: 90px;
  }
}



.text-section {
  max-width: 70%;
}

.center-card-title {
  font-family: 'NotoSans', sans-serif !important;
  font-size: 22px !important;
  /* font-weight: bold; */
}

.cow_sick{
  font-size: 22px !important;
}

@media (min-width: 1100px){
  .cow_sick{
    font-size: 24px !important;
  }
}

.cow_sick_tamil{
  font-size: 18px !important;
}

@media (min-width: 1100px){
  .cow_sick_tamil{
    font-size: 22px !important;
  }
}

.cow_sick_marati{
  font-size: 18px !important;
}

@media (min-width: 1100px){
  .cow_sick_marati{
    font-size: 22px !important;
  }
}


/* .center-card-title {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 32px;
} */

/* @media (max-width: 524px){
  .center-card-title{
    font-size: 15px !important;
  } 
}

@media (max-width: 518px){
  .center-card-title{
    font-size: 14px !important;
  } 
}

@media (max-width: 484px){
  .center-card-title{
    font-size: 13px !important;
  } 
}

@media (max-width: 452px){
  .center-card-title{
    font-size: 20px !important;
  } 
} */









@media (max-width: 767px) {
  .center-card-title {
    /* font-size: 11px !important; */
    margin-top: -2px;
  }
}

@media (min-width: 377px) and (max-width: 384px) {
  .center-card-title {
    font-size: 10px !important; /* Adjust font size for smaller screens */
    margin-top: -6px;
  }
}

/* @media(max-width: 375px){
  .carousel .carousel-item{
    margin-top: 83px !important;
  }
} */

  @media (max-width: 767px) {
    .center-card-title2 {
      font-size: 14px !important;
      margin-top: -100px;
    }
  }

  @media (max-width: 524px) {
    .center-card-title2 {
        font-size: 11px !important;
    }
}

  /* @media (min-width: 385px) and (max-width: 418px){
    .otp-tet-field-input-cls{
      font-size: 20px !important;
    }
  } */

  /* @media (max-width: 376){
    .center-card-title2 {
      font-size: 12px !important;
    }
  } */

  /* @media (max-width: 351px) {
    .center-card-title2 {
      font-size: 11px !important; 
      margin-top: -100px;
    } */
  


.treatment-card-title {
  font-weight: bold;
}

@media (max-width: 767px) {
  .treatment-card-title {
    font-size: 8px; /* Adjust font size for smaller screens */
    
  }
}



/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .banner-img-fluid {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .banner-img-fluid {
    max-width: 100%;
    height: 255px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
  .banner-img-fluid {
    max-width: 100%;
    height: auto;
    height: 266px;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .banner-img-fluid-ourteam {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 37px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .banner-img-fluid-ourteam {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 376px) {
  .center-card-title{
    margin-top: -1px;
  }
}
@media (max-width: 376px){
  .banner-img-fluid-ourteam {
    margin-top: 108px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
  .banner-img-fluid-ourteam {
    max-width: 100%;
    height: auto;
    height: 182px;
  }
}


.Team-center-card-title {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 32px;
  color: #DC7F92;
}

.center-card-ptag {
  font-size: 20px;
}
.Team-center-card-ptag {
  font-size: 20px;
  color: #fff;
}

.center-card-button {
    position: relative; /* Needed for absolute positioning of the icon */
    font-weight: 900;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  background-color: #dc7f92;
  box-shadow: 0px 5px 5px #9881a2;
  color: #232c63;
  font-size: 14px;
  /* font-weight: 600; */
}
@media only screen and (max-width: 767px) {
  .center-card-button {
    font-size: 9px; /* Adjust font size for small screens */
    padding: 0.4rem 0.8rem; /* Adjust padding for small screens */
    box-shadow: 0px 3px 3px #9881a2; /* Adjust box shadow for small screens */
    font-weight: 800;
  }
}
.second-center-card-button {
  position: relative; /* Needed for absolute positioning of the icon */
  font-weight: 900;
padding: 0.5rem 1rem;
border: none;
border-radius: 7px;
background-color: #121856;
box-shadow: 0px 5px 5px #9881a2;
color: #fff;
font-size: 14px;
/* font-weight: 600; */
}
@media only screen and (max-width: 767px) {
.second-center-card-button {
  font-size: 9px; /* Adjust font size for small screens */
  padding: 0.4rem 0.8rem; /* Adjust padding for small screens */
  font-weight: 800;
}
}
.Team-center-card-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  background-color: #dc7f92;
  color: #232c63;
  font-size: 16px;
    font-weight: bold;
  width: 161px;
 height: 45px;
  align-items: center;
  margin-top: 21px;
}

.new_home_page_Team-center-card-button-tamil{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  background-color: #232c63;
  color: #fff;
  font-size: 16px;
    /* font-weight: bold; */
  width: 190px;
 height: 52px;
  align-items: center;
  margin-top: 21px;
}

.new_home_page_Team-center-card-button-marati{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  background-color: #232c63;
  color: #fff;
  font-size: 16px;
    /* font-weight: bold; */
  width: 150px;
 height: 56px;
  align-items: center;
  margin-top: 21px;
}

.feeding_management_carousel{
  
}

.new_home_page_Team-center-card-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  background-color: #232c63;
  color: #fff;
  font-size: 16px;
    /* font-weight: bold; */
  width: 157px;
 height: 54px;
  align-items: center;
  margin-top: 21px;
}

@media (max-width: 767px) {
  .new_home_page_Team-center-card-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 7px;
    background-color: #232c63;
    color: #fff;
    font-size: 16px;
      /* font-weight: bold; */
    width: 157px;
   /* height: 32px; */
    align-items: center;
    margin-top: 1px;
  }
}

.new_home_page_card_button_tamil{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  background-color: #232c63;
  color: #F5F5FF;
  font-size: 16px;
    /* font-weight: bold; */
  width: 190px;
 height: 52px;
  align-items: center;
  margin-top: 21px;
}


.new_home_page_card_button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  background-color: #232c63;
  color: #F5F5FF;
  font-size: 16px;
    /* font-weight: bold; */
  width: 157px;
 height: 54px;
  align-items: center;
  margin-top: 21px;
}

.new_home_page_card_button_marati {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  background-color: #232c63;
  color: #F5F5FF;
  font-size: 16px;
    /* font-weight: bold; */
  width: 150px;
 height: 56px;
  align-items: center;
  margin-top: 21px;
}


.Our_Team-center-card-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  background-color: #dc7f92;
  color: #232c63;
  font-size: 16px;
    font-weight: bold;
  width: 167px;
 height: 48px;
  align-items: center;
  margin-top: 21px;
}

.Team-center-card-button .Team-Icon {
  margin-right: 10px; 
  font-size:20px;
}

@media screen and (max-width: 991px) {
  .Team-center-card-button {
    width: auto; /* Allow the button to adjust its width based on content */
    padding: 0.3rem 0.6rem; /* Adjust padding for smaller screens */
    font-size: 11px; /* Decrease font size for smaller screens */
  }

  .Team-center-card-button .Team-Icon {
    margin-right: 5px; /* Adjust icon margin for smaller screens */
  }
}
.card.bg-light-subtle .cta-section .btn {
  background-color: #898989;
  border-color: #898989;
}

@media screen and (max-width: 475px) {
  .center-card {
    font-size: 0.9em;
  }
}

.app-download-img-google {
  width: 8rem;
  border-radius: 9%;
}

.app-download-img {
  margin-top: 7px;
  width: 7.5rem;
  height: 2.5rem !important;
}


.form-control::placeholder {
  font-size: 0.95rem;
  color: #aaa;
}

.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.discount-ribbon {
  position: absolute;
  top: 0;
  background-color: #05de29;
  color: #fff;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0%, 50% 0);
}

.discount-ribbon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-style: solid;
  border-color: transparent #3ce750 transparent transparent;
}

/* @media only screen and (max-width:768px) {
  .testimonial-name-city {
  
  }
} */

.testimonial-name-city {
  color: #DC7F92;
  /* text-align: center; */
  /* margin-top: 7rem; */
}

.home-test-img-play-btn-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-group {
  width: 350px;
}

.input-group-text {
  position: absolute;
  left: 104px;
  background-color: #fff;
  padding-right: -5px;
  border-right: none;
}

.search-icon {
  height: 24.5px;
}

.search-div {
  border-left: "2px solid black" !important;
}

.second-row {
  box-shadow: 1px solid #000 !important;
}

.fancy-line {
  border: 0;
  height: 1px;
  position: relative;
  margin: 0.5em 0;
}

.fancy-line:before {
  top: -0.5em;
  height: 1em;
}

.fancy-line:after {
  height: 0.5em;
  top: calc(-0.5em + 1px);
}

.fancy-line:before,
.fancy-line:after {
  content: '';
  position: absolute;
  width: 100%;
}

.fancy-line,
.fancy-line:before {
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 75%);
}

body,
.fancy-line:after {
  background: #f4f4f4;
}


body {
  background-color: #F5F5FF;
}

.wrapper {
  padding: 70px 0;
}

.center-slider {
  padding: 40px 0;
}
.center-slider .slick-slide {
  color: #FFF;
  height: 400px; 
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  width: calc(25% - 30px) !important; 
}

.center-slider .slick-slide, 
.center-slider .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
  transform: scale(0.8); /* Reduced scale for inactive slides */
  transition: all 0.4s ease-in-out;
}

/* Active center slide */
.center-slider .slick-center, 
.center-slider .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
  transform: scale(1.2); /* Increased scale for active slide */
  width: 500px !important; /* Increased width for active slide */
  border-radius: 10px !important;
}

.center-slider .slick-current.slick-active {
  transform: scale(1.8);
  z-index: 999;
}

.slick-next, .slick-prev {
  z-index: 5;
}

.slick-next {
  right: 15px;
}

.slick-prev {
  left: 15px;
}

.slick-next:before, .slick-prev:before {
  font-size: 26px;
  color: red;
}

/* .slick-prev:before .slick-next:before {
  color: red;
} */

/* Ensure first slide is visible */
.center-slider .slick-list {
  padding: 0 !important;
}

.slick-next:before{
  color: red;
}

/* Adjust slide width for 4 visible slides */
.center-slider .slick-track {
  display: flex;
  gap: 15px;
}