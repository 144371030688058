/* @font-face {
    font-family: 'CircularStd-Book';
    src: url('../CircularStd-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */

/* @font-face {
    font-family: 'NotoSans-Medium';
    src: url('../CircularStd-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd-Bold';
    src: url('../CircularStd-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */


@font-face {
    font-family: 'NotoSans-Medium';
    src: url('../NotoSans-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans-Bold';
    src: url('../NotoSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


body {
    font-family: 'NotoSans-Medium', 'NotoSans-Bold', sans-serif;
}

