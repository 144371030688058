img.market-animals-cate-filter-img {
    height: 40px;
    width: 40px;
    border-radius: 2px;
}

.market-animals-cate-filter-main-div {
    border-radius: 8px;
    border: 1px solid #D7D7D7;
    background: #F8F8F8;
}

.market-animals-cate-filter-header {
    border-radius: 8px 8px 0px 0px;
    background: #232C63;
    height: 50px;
    display: flex;
    align-items: center;
    color: #FBFBFB;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    padding: 0 1rem;
}

.market-animals-cate-filter-div {
    padding: 5px 10px;
    flex-direction: row;
    align-items: center;
    display: inline-flex;
    gap: 12px;
    margin-left: auto;
    margin-right: auto;
}

.products_list_cate_filter_category_before_map {
    padding: 10px 0;
}

.market-place-cate-filter-active-div {
    border-radius: 4px;
    background: #E8E8E8;
    border-bottom: 3px solid #232c63 !important;
}

.market-animals-cate-filter-category {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    border-bottom: 1px solid #D3D3D3;
    margin-top: 5px;
}

.market-animals-cate-filter-title {
    color: #474747;
    text-transform: capitalize;
    font-style: normal;
}

.marketplace-4img-front {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    object-position: center top;
    border: 1.5px solid white;
    border-radius: 5px 0 0 0;
}

img.marketplace-4img-side {
    width: 50%;
    height: 10rem;
    object-fit: cover;
    object-position: center top;
    border: 1.5px solid white;
    border-radius: 0 0 0 5px;
}

.marketplace-4img-body {
    width: 50%;
    height: 10rem;
    object-fit: cover;
    object-position: center top;
    border: 1.5px solid white;
}

.marketplace-4img-video {
    width: 100%;
    height: 25rem;
    object-fit: cover;
    object-position: center top;
    border: 1.5px solid white;
    border-radius: 0 5px 5px 0;
}

.maretplace-cattle-card-head {
    padding: 5px 7px;
}

.marketplace-cattle-card-postedby {
    color: #848484;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 2px 0;
}

.marketplace-cattle-card-div {
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
}

.marketplace-4img-col-1 {
    width: 70%;
}

.marketplace-4img-col-2 {
    width: 30%;
    position: relative;
}

.marketplace-cattle-images-main-div {
    border: 1px solid white;
}

.marketplace-cattle-card-title {
    color: #060606;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    font-family: 'NotoSans-Medium';
}

.marketplace-cattle-card-rate {
    color: #008000;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 11.5px;
    float: right;
}

.marketplace-cattle-card-place {
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 5px 0;

}

.marketplace-cattle-ratings .star-svg {
    height: 20px;
    width: 20px;
}

svg.marketplace-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.marketplace-cattle-age-div {
    padding: 14px;
    border-radius: 4px;
    border: 1px solid #232c63;
    color: #232C63;
}

.marketplace-cattle-age-header {
    color: #060606;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
}

.marketplace-cattle-age-value {
    color: #232c63;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-top: 7px;
    font-family: 'NotoSans-Medium';
}

.marketplace-cattle-age-main-div-cls {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.marketplace-cattle-listing-decription {
    color: #3b3b3b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: 'NotoSans-Medium';
}

.maretplace-cattle-view {
    color: #3B3B3B;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

svg.marketplace-cattle-eye-icon {
    font-size: 18px;
    margin-right: 5px;
}

.marketplace-cattle-btn-div {
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    cursor: pointer;
}

.marketplace-cattle-btn-div {
    gap: 10px;
}

svg.marketplace-cattle-btn-icons {
    font-size: 20px;
    margin-right: 2px;
}

.marketplace-3img-front {
    height: 25rem;
    width: 33.33%;
    object-fit: cover;
    object-position: center top;
    border: 1.5px solid white;
}

.marketplace-cattle-img-first {
    border-radius: 5px 0 0 5px;
}

.marketplace-cattle-img-last {
    border-radius: 0 5px 5px 0;
}

.marketplace-3img-col-2 {
    position: relative;
    width: 33.33%;
}

.marketplace-3img-video {
    height: 25rem;
    width: 100%;
    object-fit: cover;
    object-position: center top;
    border: 1.5px solid white;
}

.marketplace-2img-front {
    height: 25rem;
    width: 50%;
    object-fit: cover;
    object-position: top top;
    border: 1.5px solid white;
}

.marketplace-cattle-2img-first {
    border-radius: 5px 0 0 5px;
}

.marketplace-cattle-2img-last {
    border-radius: 0 5px 5px 0;
}

.marketplace-2img-col-2 {
    position: relative;
    width: 50%;
}

.marketplace-2img-video {
    height: 25rem;
    width: 100%;
    object-fit: cover;
    object-position: top top;
    border: 1.5px solid white;
}

.marketplace-1img-front {
    height: 25rem;
    width: 100%;
    object-fit: cover;
    object-position: top top;
    border: 1.5px solid white;
    border-radius: 5px;
}

.marketplace-1img-col-2 {
    position: relative;
    width: 100%;
}

.marketplace-1img-video {
    height: 25rem;
    width: 100%;
    object-fit: cover;
    object-position: top top;
    border: 1.5px solid white;
    border-radius: 5px;
}

.no-data-found-div-cls {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-top: 5rem;
}

img.marketplace-modal-images {
    height: 20rem;
    width: 100%;
    object-fit: contain;
}

.slider-arrow-custom-style-marketplace-img .slick-prev,
.slider-arrow-custom-style-marketplace-img .slick-next {
    color: #232c63 !important;
}

.slider-arrow-custom-style-marketplace-img .slick-next {
    right: 0 !important;
}

.slider-arrow-custom-style-marketplace-img .slick-prev {
    left: 0 !important;
}

.marketplace-img-modal-div {
    position: relative;
    width:100%
}

.marketplace_seller_main_div {
    border-radius: 12px;
    border: 1px solid #B1B1B1;
    background: #FFF;
    margin: 35px 0;
}

img.marketplace_seller_profile_img {
    border-radius: 50%;
    height: 8rem;
    width: 8rem;
    object-fit: cover;
    object-position: center;
}

.marketplace_seller_card_div {
    margin: 15px;
    gap: 2rem;
    flex-wrap: wrap;
    
}

.marketplace_seller_cattle_tab {
    color: #0d1026;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    border-radius: 27px;
    background: #dfdfdf;
    text-align: center;
    padding: 8px 22px;
    cursor: pointer;
    font-family: 'NotoSans-Medium';
}

.marketplace_seller_cattle_tabs {
    gap: 1rem;
    flex-wrap: wrap;
    margin-left:16px
}   

.marketplace_seller_cattle_tab_active {
    background-color: #232C63;
    color: #FBFBFB;
}

.marketplace_seller_catte_list_col_card {
    border-radius: 8px;
    border: 1px solid #B1B1B1;
    background: #FFF;
    padding: 12px 14.137px 12px 15px;
}

.marketplace_seller_catte_list_col_title {
    color: #060606;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    font-family: 'NotoSans-Medium';
}

.marketplace_seller_catte_list_col_rate {
    color: #3B3B3B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.5px;
}

img.marketplace_seller_cattle_img {
    width: 100%;
    height: 235px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.marketplace_seller_cattle_active_btn {
    color: #008000;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 4px;
    padding: 4px 8px;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'NotoSans-Medium';
}

.marketplace_seller_cattle_sold_btn {
    color: #E10000;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    font-family: 'NotoSans-Medium';
}

.marketplace_seller_cattle_Draft_btn {
    color: #FFB300;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    font-family: 'NotoSans-Medium';
}

.marketplace_seller_cattle_Review_btn {
    color: #003FE1;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    font-family: 'NotoSans-Medium';
}

.marketplace_seller_cattle_Published_btn {
    color: #008000;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    font-family: 'NotoSans-Medium';
}

.marketplace_seller_cattle_SoldOut_btn {
    color: #E10000;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    font-family: 'NotoSans-Medium';
}

.marketplace_seller_cattle_Expired_btn {
    color: #FF6B00;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    font-family: 'NotoSans-Medium';
}

.marketplace_cattle_category_for_small_display {
    display: none !important;
}

.marketplace_cattle_category_for_small {
    justify-content: space-between;
    text-align: center;
    line-height: 2;
    gap: 20px;
}

img.market-animals-cate-filter-img-ss {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 5px;
}

.market-animals-cate-filter-title-ss {
    color: #474747;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 5px;
}

.marketplace_cattle_cate_filter_cate_ss {
    border-radius: 8px;
    border: 1px solid #D7D7D7;
    background: #F8F8F8;
    padding: 5px 10px;
}

.market-place-cate-filter-active-div-for-ss {
    border-radius: 4px;
    background: #E8E8E8;
    border-bottom: 3px solid #232C63;
}

.marketplace_cattle_grid_btn_main_div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.marketplace_cattle_grid_btn_row.row {
    padding-left: 0;
    padding-right: 0;
}

.marketplace_cattle_grid_btn_col2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
}

.marketplace_cattle_btn_border {
    border: 1.5px solid;
    height: 80%;
    color: #757575;
    border-radius: 15px;
}

.marketplace_share_btn {
    background-color: transparent;
    border: none;
    color: #757575;
    font-weight: 500;
}

.profile_page_icon_cls {
    font-size: 18px;
}

.profile_page_icon_active {
    color: #232c63;
    font-weight: 600;
}

.marketplace_seller_edit_main_div {
    border-radius: 12px;
    border: 1px solid #B1B1B1;
    background: #FFF;
    margin-bottom: 35px;
    margin-left:11px;
    width:97%;
}

.marketplace-seller-edit-icon {
    color: #232C63;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    margin: 15px;
    white-space: nowrap;
    text-decoration: underline;
}

svg.marketplace-seller-pencil-icon-cls {
    margin-bottom: 4px;
}

.marketplace-seller-cattle-edit-icon {
    color: #757575;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
}

.one_line_ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.seller_profile_paynow_btn {
    white-space: nowrap;
    color: #ffffff;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    background-color: #0c1d8b;
    height: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
}

.row-padding-remover {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0;
    margin-right: 0;
}

.seller_profile_page_main_div {
    border-radius: 8px;
    border: 1px solid #D7D7D7;
    background: #F8F8F8;
}

.seller_profile_page_main_div_small {
    display: none;
}

.market-animals-cate-filter-category_small {
    border: 1px solid #D3D3D3;
    margin: 5px;
    border-radius: 25px;
    background-color: #FBFBFB;
}

.market-place-cate-filter-active-div_small {
    border-radius: 25px;
    background: #E8E8E8;
    border-bottom: 3px solid #232c63 !important;
}

.input-bg-none {
    background-color: transparent;
    /* border: 1px solid #DC7F92; */
    border: 1px solid #262626;
    border-radius: 4px;
}

.farm-detail-box{
    background-color: transparent;
    border: 1px solid #DC7F92;
    /* border: 1px solid #262626; */
    border-radius: 0px;
}

.input-bg-none:focus {
    background-color: transparent;
}

.form-label-custom-style {
    color: #060606;
    line-height: normal;
    font-size:18px;
}

img.own-seller-profile-img-edit-mode {
    height: 130px;
    width: 130px;
    object-fit: cover;
    border-radius: 50%;
}

.own-seller-profile-img-col {
    gap: 2rem;
}

.own-seller-profile-upload-btn {
    margin-top: 15px;
    border-radius: 4px;
    background: #232C63;
    color: #fbfbfb;
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px 12px;
    cursor: pointer;
}

svg.seller-upload-img-camera-icon {
    font-size: 16px;
    margin-bottom: 2px;
}

.body-overflow-hide {
    position: relative;
    overflow: hidden;
}

.user-profile-address-book-add-btn {
    height: fit-content;
}
.button-container {
    display: flex;
    justify-content: flex-start;
}

.products_lits_page_sidebar_filter_main_div {
    border-radius: 7px;
    border: 1px solid #B1B1B1;
    background: #FBFBFB;
    padding: 25px 15px;
}

.products_list_page_filter_by_text {
    color: #060606;
    font-weight: 200;
    line-height: normal;
    font-family: 'NotoSans-Medium';
    font-size: 14px;
}

.products_list_page_clear_all_btn_div {
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    font-family: 'NotoSans-Medium';
}

.products_list_cate_filter_category {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5px;
}

.products_list_category_checkbox {
    height: 20px;
    width: 20px;
    border-radius: 2px !important;
    border: 1px solid #B1B1B1;
    background-color: transparent;
}

.products_list_category_checkbox:checked {
    background-color: #232c63;
    border-color: #232c63;
}

.products_list_category_checkbox:focus {
    box-shadow: none;
}

.market-animals-cate-filter-title-active {
    color: #232c63;
}

.products_list_page_filter_by_text_sub_div {
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 5px;
}

.product-list-view-card-div {
    background-color:  #fbfbfb;
    margin-bottom: 20px;
    border-radius: 8px;
    padding-left: 0;
    padding-right: 0;
    border: 1px solid #d7d7d7; 
}
.product-rating-div {
    background-color: #fbfbfb;
    margin-bottom: 20px;
    border-radius: 8px;
 /* margin-right: 23px; */
 margin-left: 32px;
}
.product-side-image-rating-div {
    background-color: #fbfbfb;
    margin-bottom: 20px;
    border-radius: 8px;

}

.product_list_view_col1_div {
    position: relative;
    justify-content: center;
}

.product_list_view_col2_div {
    padding: 15px;
}

.product-list-view-product-amount-del-tag {
    color: #DC7F92;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.36px;
}

.product-listview-addtocart {
    width: fit-content;
    background-color: #232C63;
    color: #fff;
    border: none;
    padding: 6px 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.product_list_view_cate_filter_page_main_div {
    border-radius: 8px;
    border: 1px solid #D7D7D7;
    background: #F8F8F8;
}

.product-grid-view-card {
    background-color: #fbfbfb;
    border-radius: 8px;
    position: relative;
    border: 1px solid  #D7D7D7;
}

.card_grid_view_img {
    height: 10rem;  
    object-fit: cover;
    width: 50%;
    /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25); */
    margin: 20px 0;
}

.product_grid_view_cls_for_details_main {
    padding: 0 20px 20px 20px;
}

.product-details-package-weight-label {
    color: #474747;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.32px;
}

.product-details-package-weight-value {
    border-radius: 4px;
    border: 1px solid #232c63;
    background: #ffffff;
    width: -moz-fit-content;
    width: fit-content;
    color: #232c63;
    font-size: 15px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 9px;
    margin-top: 10px;
    font-family: 'NotoSans-Medium';
}

.product-details-select-qty-tag {
    margin-left: 0 !important;
    background-color: transparent;
    border: 1px solid #060606 !important;
    padding: 9px !important;
    margin-top: 10px;
}

.product-details-addtocart {
    border-radius: 5px;
    font-size: 18px;
    width: 100%;
    border: 1px solid #232c63;
    background-color: #f3f3f3 !important;
    color: #000000 !important;
    white-space: nowrap;
    padding-top: 15px;
    padding-bottom: 15px;
}

.product-details-description-label {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* letter-spacing: 0.4px; */
    font-family: 'NotoSans-Medium';
    padding-top: 10px;
}

.product-details-related-product-label {
    color: #232c63;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* letter-spacing: 1.5px; */
    margin: 20px 0;
    font-family: 'NotoSans-Medium';
}

.product-details-related-product-img {
    height: 11rem;
    width: 65%;
}

img.product-details-related-product-img {
    height: 294px;
    /* height: 15rem; */
    width: 100%;
    object-fit: cover;
    border-radius: 1rem 1rem 0 0;
}

.product-details-related-product-img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
}

.related-product-name.cursor-pointer {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 10px 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    margin-bottom: auto;
}

.slider-arrow-custom-style-for-related-products .slick-prev {
    left: 0px !important;
    color: #232c63;
}

.slider-arrow-custom-style-for-related-products .slick-next {
    right: 0px !important;
    color: #232c63;
}

.product-details-col2-content-cls {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
}

.modal-container-cls.container {
    border: 1px solid #b1b1b1;
    border-radius: 10px;
    padding: 20px;
}

.cart-page-product-list-col {
    background-color: #fbfbfb;
    border-radius: 6px;
    padding: 0 1rem;
}

.cart-page-product-image-name-div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #BFBFBF;
    padding: 10px;
}

.cart-page-product-image-name-div:last-child {
    border-bottom: none;
}

.cart-checkout-btn-div {
    border-top: 1px solid lightgray;
}

.checkout-products-list-col {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    border-bottom: 0.12rem solid #BFBFBF;
    padding-bottom: 10px;
    margin-top: 10px;
}

.checkout-product-list-main-div-col:last-child .checkout-products-list-col {
    border-bottom: none;
}

.hr-remove-margin {
    margin: 0;
}

.out_doctors_name_designation_experience_div {
    line-height: 2;
    margin-bottom: 10px;
}

.out-doctor-content-col {
    color: #DC7F92;
    text-align: center;
}
.out-doctor-designation-cls {
    color: #0D1560;
    text-align: center;
    font-size: 12px;

}


.our_doctors_col_btns_cls {
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.our_docutors_col2_cls {
    position: relative;
}

.blog_page_card_body.card-body {
    background-color: #fbfbfb;
    border: 1px solid #b1b1b1;
    border-radius: 6px;
}

.disclaimer-modal-content-div {
    line-height: 1.8;
    color: #3B3B3B;
    margin: 0px 10px;
}

.marketplace-filter-clear-div {
    font-size: 14px;
}

.marketplace_filters_main_div {
    padding: 10px 10px 30px 10px;
}

.cattle_list_milk_productions_span {
    color: #dc7f92;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.44px;
    font-size: 18px;
    font-family: 'NotoSans-Medium';
    margin-left: 0.5rem;
}

.maretplace-cattle-view {
    color: #003fe1;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    bottom: 0;
    white-space: nowrap;
    right: 0;
}

.marketplace-cattle-rate-and-view {
    position: relative;
}

.marketplace-cattle-provider-col {
    display: flex;
    align-items: center;
    gap: 10px;
}

.marketplace-cattle-list-profile-img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.marketplace-cattle-card-postedby-div {
    color: #232C63;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    font-family: 'NotoSans-Medium';
}

.marketplace-cattle-btn-col-cls {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    height: fit-content;
    align-self: end;
    padding-right: 0;
}

.marketplace_services_card_div {
    border-radius: 8px;
    border: 1px solid #D3D3D3;
    margin-bottom: 12px;
    background-color: #fbfbfb;
    padding: 15px;
    align-items: center;
    gap: 2rem;
}
.blog-title {
    color: #232c63 !important;
    white-space: normal; /* Allow text to wrap */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Show up to 4 lines */
    -webkit-box-orient: vertical;
    height: auto; /* Adjust based on content */
    text-transform: capitalize;
    margin-left: 40px;
  }
  

  .blog-image {
    width: 125%;
    height: 100%;
    object-fit: contain;
  }
  
  .blog-page-card-body {
    padding: 1rem;
  }
  
  
 
  
  .blog-title::after{
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
  

.marketplace_services_img_cls {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 8px;
}

span.marketplace_services_location_label_span {
    color: #008000;
    font-style: normal;
    font-weight: 450;
    letter-spacing: 0.48px;
}

.marketplace_services_location_div {
    color: #3B3B3B;
    font-size: 17px;
    font-style: normal;
    font-weight: 450;
    letter-spacing: 0.44px;
    margin: 10px 0;
}

.marketplace_view_mobileno {
    color: #232C63;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.4px;
}

.home-search-div {
    position: relative;
}

.home-search-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
    color: #232c63;
}

.home-search {
    border: 1px solid #BBBBBB;
    background: #FFF;
    padding-left: 2.5rem;
    color: #B1B1B1;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding-top: 7px;
    padding-bottom: 7px;
    width: 20rem;
}

.market_place_title_main_col {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.market_place_bottom_content_col {
    margin-top: 1.7rem;
}

.page_heading_div_cls {
    font-size: 22px;
    /* font-size: 18px; */
    color: #232c63;
    margin-top: -20px;
    font-weight: 400;
    /* letter-spacing: 0.6px; */
    font-family: 'NotoSans-Medium';   
    padding: 0px ;
    margin-bottom: 4px; 
    /* margin-top: 62px; */
}
.page_heading_div_cls-founder_page {
    font-size: 24px;
    /* font-size: 18px; */
    color: #232c63;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-family: 'NotoSans-Medium';
    padding: 0px;
    margin-bottom: 4px;
    margin-top: 28px; 

    @media (max-width: 2560px) {
        margin-top: 134px;
    }
}

.page_heading_div_cls-product_List {
    font-size: 22px;
    /* font-size: 18px; */
    color: #232c63;
    font-weight: 400;
    /* letter-spacing: 0.6px; */
    font-family: 'NotoSans-Medium';   
    padding: 0px ;
    margin-bottom: 4px;
    /* margin-top: 28px; */
}
.treatment_div_cls {
    font-size: 22px;
    color: #232c63;
    font-weight: 400;
    /* letter-spacing: 0.6px; */
    font-family:'NotoSans-Medium';   
    padding: 0px ;
    /* margin-bottom: 4px; */
    margin-top: 28px;
}
.home_page_common_consultaions {
    font-size: 24px;
    color: #232c63;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-family: 'NotoSans-Medium';   
    padding: 0px ;
}
.mt-custom {    
  padding-top: 40px;
}

.post_animal_edit_heading {
    font-size: 18px;
    color: #232c63;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-family: 'NotoSans-Medium';   
    padding: 0px ;
    margin-bottom: 4px;
    /* margin-top: 28px; */
}
.page_heading_rating {
    font-size: 18px;
    color: #232c63;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-family: 'NotoSans-Medium';   
    padding: 0px ;
    margin-bottom: 9px;
    /* margin-top: 28px; */

}
.page_heading_farm-deatils {
    font-size: 18px;
    color: #232c63;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-family: 'NotoSans-Medium';   
    /* padding: 0px ; */
    margin-bottom: 4px;

}

.button-container-userprofile {
    display: flex;
    justify-content: flex-end; 
    /* padding-right: 85px;  */
    padding-bottom: 10px;
    /* margin-right: -32px; */
}
/* @media (min-width: 1024px) {
    .button-container-userprofile {
        padding-right: 10px
    }
} */
/* @media (max-width: 768px) {
    .button-container-userprofile {
        padding-right: 10px
    }
} */

.button-container {
    display: flex;
    justify-content: flex-end; 
    padding-right: 85px; 
}
@media (min-width: 1024px) {
    .button-container {
        padding-right: 124px; /* Increased padding for screens 1024px and above */
    }
}

@media (min-width: 1024px) {
    .Add-button-container {
        padding-right: 128px; /* Increased padding for screens 1024px and above */
    }
}

.page_heading_div_cls-doctor {
    font-size: 22px;
    color: #232c63;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-family: 'NotoSans-Medium';
    /* padding: 16px; */
    margin-bottom: 8px;
    margin-top: 19px;
}
.Doc_heading_div_cls {
    font-size: 22px;
    color: #232c63;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-family: 'NotoSans-Medium';   
    text-align: center;
    margin-top: 5px;
}
.New_achievement_div_cls {
    font-size: 22px;
    color: #232c63;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-family: 'NotoSans-Medium';   
    text-align: center;
    margin-top: 5px;
    margin-bottom: 33px;
}

.achievement-img-aligner {
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative !important;
}

.hello{
    position: relative !important;
}

.achievement-shortdiscription {
    position: absolute;
    cursor: pointer;
    top: 13.5rem;
    z-index: 100;
    height: 4.5rem;
    width: 100%;
    overflow: hidden;
    background-color: #ededed;
    opacity: 0.85;
    border-Width: 1px;
    border-color: #000;
    border-Radius: 16px 16px 16px 16px;
}

.achievement-hover {
    position: absolute;
    cursor: pointer;
    top: 11.5rem;
    z-index: 100;
    height: 6.7rem;
    width: 100%;
    overflow: hidden;
    background-color: #ededed;
    opacity: 0.85;
    border-radius: 14px 14px 14px 14px ;
}

.achievement-Short-Title {
    font-size: 18px;
    line-height: 36px;
    font-weight: 400;
    color: #232C63;
    padding: 5px 0px 0px 15px;
}

.achievement-Short-Title-Hover {
    font-Size: 18px;
    line-height: 36px;
    font-Weight: 400;
    opacity: 100%;
    /* width: 2%; */
    color: #232C63;
    padding: 5px 0px 0px 15px;
}



.page_heading_Grid {
    font-size: 22px;
    color: #232c63;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-family: 'NotoSans-Medium';
    background-color: pink;
    padding-bottom: 20px;
    padding-top:3px;
    margin-top: 39px;
}

.market_place_animals_imgs_borderradious {
    border-radius: 5px !important;
}

.market_place_livestock_btnd {
    background-color: #232C63;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.marketplace-cattle-card-place-view-profile {
    color: #757575;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 5px 0;
    font-family: 'NotoSans-Medium';
    display: flex;
    gap: 5px;
}

.marketplace-cattle-card-postedby-iew-profile {
    color: #757575;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 2px 0;
}

.seller_profile_page_register_form_grup {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.seller_profile_form_label {
    font-size: 18px;
    color: #606060;
}

.seller_profile_form_input {
    background-color: transparent;
    border: 0.1rem solid #595959;
    border-radius: 4px;
    height: 3rem;
    font-size: 18px;
}

.seller_profile_page_register_form_grup select {
    margin-left: 0px;
}

.address-book-address-seller-profile-register {
    line-height: 1.8;
    font-size: 18px;
    color: black;
}

.profile-save-btn-cls {
    background-color: #232c63;
    color: #fff;
    border: transparent;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 18px;
    font-family: 'NotoSans-Medium';
}

.seller_profile_form_input:focus {
    background-color: transparent;
    border-color: #595959;
}

.product_list_card_qty_select_cls {
    background-color: transparent;
    padding: 5px;
}

.product_list_view_icons_column_cls {
    margin-bottom: 1rem;
    padding-right: 0;
}

.product-grid-disc-height {
    height: 92px;
}

.product-grid-view-desc {
    color: #757575 !important;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    line-height: 1.6;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
}

.product_page_price_main_div_cls {
    white-space: nowrap;
}

.product-details-product-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    color: #060606;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    font-family: 'NotoSans-Medium';
}

.product-details-card-col-padding {
    padding: 1rem;
    border: 1px solid #BABABA;
}

.product-details-page-ratings .star-svg {
    height: 35px;
    width: 30px;
}

.product-details-ratings-main-div {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0.8rem 0;
}

.product-details-ratings-text {
    color: #232C63;
    text-decoration: underline;
}
.update-seller-profile-text {
    color: #232C63;
    /* text-decoration: underline; */
    margin-left: -21px;
}
.update-seller-profile-text {
    color: #232C63;
    text-decoration: underline;
    /* margin-left: -21px; */
}
.save-changes-button{
    padding-left: 252px;
}
.product_details_related_products_div_for_bg {
    background-color: #fff;
    margin-bottom: 3rem;
    padding: 1.5rem 0;
}

.relate_product_bg_color_div_home {
    background-color: #f3f3f3;
    /* padding: 2rem; */
    border-radius: 1rem;
    border: 1px solid #ededed;
    height: 458px;
    /* height: 454px; */
}
.relate_product_bg_color_div {
    background-color: #f3f3f3;
    border-radius: 1rem;
    border: 1px solid #ededed;
    /* height: 454px; */
}

.related_product_card_body {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    padding: 8px;
    border: 0px solid #e1e1ee;
}

.description-container {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits the text to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden; /* Hides the overflow text */
    text-overflow: ellipsis; /* Adds an ellipsis at the end */
  }
  

.related-product-name {
    font-family: 'NotoSans-Medium';
    margin-bottom: 1rem;
}

.related_product_pack_weight_div {
    color: dimgrey;
    /* padding-left: 10px; */
    font-size: 14px;
    margin-bottom: 10px;
}


.nav-link.active {
    background-color: #232C63 !important;
    color: #fff !important;
    opacity: 70%;
  }

.related_product_card_price_Del {
    display: flex;
    gap: 8px;
    font-size: 12px;
    color: #858585;
    height: 1.5rem;
}
.related-product-final-price-span{
    font-size: 18px;
}

.related_product_dis_value {
    color: #1aab2a;
}

.related_product_dis_value {
    color: #1aab2a;
}

.relatedproduct-viewproduct-btn {
    padding: 6px 25px;
    font-size: 14px;
    /* width: 100%; */
    margin-top: -6px;
}
.relatedproduct-viewproduct-btn_home {
    padding: 6px 25px;
    font-size: 14px;
    width: 53%;
    /* width: 100%; */
    margin-top: 6px;
}

/* .swiper-button-next{
    top: 0 !important
} */




.blog-swiper .swiper-pagination-bullet{
    background-color: rgba(0,0,0,0.6);
    margin-right: 17px !important;
}

.blog-swiper .swiper-pagination-bullet-active{
    background-color: black;
}


.blog-swiper .swiper-button-next{
    background: #dc7f92;
    border-radius: 8px;
    box-shadow: 0px 4px 3px #b6b6b6;
    color: white;
    width: 45px;
    height: 45px;
    padding: 5px;
}

.blog-swiper .swiper-button-prev{
    background: #dc7f92;
    border-radius: 8px;
    box-shadow: 0px 4px 3px #b6b6b6;
    color: white;
    width: 45px;
    height: 45px;
    padding: 5px;
}

@media (max-width: 768px){
.custom-new-button{
    display: none;
}
}


.slider-arrow-custom-style-for-related-products .slick-prev,
.slider-arrow-custom-style-for-related-products .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 45px;
    height: 45px;
    padding: 5px;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #fff;
    border: none;
    outline: none;
    background: #dc7f92;
    border-radius: 8px;
    box-shadow: 0px 4px 3px #b6b6b6;
}
.testimonial-main-name {
    /* Initial height */
    height: 70px;
  }
  
  @media (max-width: 425px) {
    .testimonial-main-name {
      /* Adjust height for smaller screens */
      height: auto;
    }
  }
  .testimonial-name-city {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 425px) {
    .testimonial-name-city {
        font-size: 14px; /* Adjust the font size as needed */
        /* padding-left: 10px; */
        margin-right: 10px;
    }
}

.test-city-and-name{
    text-transform: capitalize;
}


@media (max-width: 425px) {
    .test-city-and-name {
        text-transform: capitalize;
        font-size: 14px; /* Adjust the font size as needed */
        padding-left: 20px;
    }
}


.slider-arrow-custom-style-for-related-products .slick-next {
    right: -15px !important;
}

.slider-arrow-custom-style-for-related-products .slick-prev {
    left: -15px !important;
    z-index: 1;
}

.product_details_page_btns_col_cls {
    display: flex;
    gap: 3rem;
}

.product-details-buynow-btn {
    width: 100%;
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
    white-space: nowrap;
}

.card-product-details-div {
    display: flex;
    gap: 2rem;
    align-items: center;
    width: 65%;
}

.cart-product-name {
    font-size: 18px;
    font-family: 'NotoSans-Medium';
    color: black;
    margin-bottom: 10px;
    display: -webkit-box;
    /* -webkit-box-orient: vertical; */
    overflow: hidden;
    word-wrap: break-word;
    white-space: normal;
    -webkit-line-clamp: 1;
    /* text-wrap: wrap !important; */
}

.cart-product-weight-cls {
    color: #606060;
    margin-bottom: 10px;
}

.cart-produtcs-qty-label {
    color: #060606;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.32px;
    margin-bottom: 12px;
    margin-top: 10px;
    font-family: 'NotoSans-Medium';
    white-space: nowrap;
}

.cart-product-select-qty-tag {
    margin-left: 0 !important;
    background-color: #f3f3f3;
    border: 1px solid #414141 !important;
    padding: 8px !important;
    margin-top: 10px;
}

.cart_product_remove_btn {
    display: flex;
    /* align-items: end; */
    gap: 5px;
    color: #e10000;
    letter-spacing: 1px;
    font-size: 14px;
}

.cart-product-price-col {
    padding-top: 1rem;
    /* padding-bottom: 1rem; */
}

.cart-product-price-title {
    font-size: 18px;
    font-family: 'NotoSans-Medium';
}

.cart-product-price-dl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}

.cart-product-label-1 {
    font-weight: 100;
}

.cart-product-subtotal {
    font-family: 'NotoSans-Medium';
}

.cart-product-dis-dd {
    color: #1aab2a;
}

.cart-product-label-2 {
    font-size: 18px;
    font-family: 'NotoSans-Medium';
    font-weight: 500;
}

.cart-page-btns-div {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    gap: 10px;
}

.cart-page-cancel-btn {
    width: 100%;
    background-color: #fff !important;
    color: #232c63 !important;
    border: 1px solid;
    border-radius: 5px;
    font-family: 'NotoSans-Medium';
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cart-page-ok-btn {
    width: 100%;
    border-radius: 5px;
    font-family: 'NotoSans-Medium';
    font-size: 18px;
}

.checkout-col1 {
    background-color: #fff;
    padding: 1rem 0.5rem;
    border-radius: 5px;
}

.checkout-step1-label {
    font-family: 'NotoSans-Medium';
    font-size: 18px;
}

.checkout-radio-btn {
    height: 18px;
    width: 18px;
}

.checkout-delivery-type-label-values {
    color: #606060;
    font-size: 17px;
}

.selected-delivery-type-label-active {
    color: #060606;
}

.checkout-delivery-address-font {
    color: #505050;
    font-size: 16px;
    line-height: 1.8;
}

.discount-code {
    width: 33.33%;
}

.offer-container {
    border: 1px solid #DC7F92;
    text-align: center;
    margin: 5px;
    /* line-height: 2; */
    padding: 10px;
    position: relative;
}

.checkout-coupon-code-maind-cls {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
}

.offer-text {
    color: #101010;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.code-text {
    color: #757575;
    font-size: 14px;
    letter-spacing: 0.6px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.apply-button {
    color: #232c63;
    font-size: 14px;
    font-family: 'NotoSans-Medium';
    cursor: pointer;
}

.applied-text {
    color: green;
    font-size: 14px;
    font-family: 'NotoSans-Medium';
}

.offer-container::before {
    content: '';
    position: absolute;
    height: 25px;
    width: 20px;
    border-radius: 0 150px 150px 0;
    background-color: #fff;
    border-right: 1.5px solid #dc7f92;
    margin-top: 2.5rem;
    left: -8px;
    top: 0;
}

.offer-container::after {
    content: '';
    position: absolute;
    height: 25px;
    width: 20px;
    border-radius: 0 150px 150px 0;
    background-color: #fff;
    border-right: 1.5px solid #dc7f92;
    margin-top: 2.5rem;
    transform: rotate(180deg);
    right: -8px;
    top: 0;
}

.checkout-page-price-details-label-1 {
    color: #505050;
    font-weight: 100;
}

.checkout-view-product-amount-del-tag {
    color: #DC7F92;
    font-size: 16px;
    letter-spacing: 0.36px;
}

.checkout-product-final-price {
    color: #232C63;
    font-size: 16px;
    font-family: 'NotoSans-Medium';
}

.checkout-page-hr-cls {
    color: #BFBFBF !important;
    border-width: 0.12rem;
}

.checkout-page-hr-cls {
    border: 0.12px solid #BFBFBF;
    margin: 10px 0;
}

.checkout-page-cancel-btn {
    background-color: #fff !important;
    color: #232c63 !important;
    border: 1px solid;
    border-radius: 5px;
    font-family: 'NotoSans-Medium';
    font-size: 18px;
    padding: 0.5rem 3rem;
}

.checkout-page-btns-div {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    gap: 2rem;
}

.checkout-page-ok-btn {
    border-radius: 5px;
    font-family: 'NotoSans-Medium';
    font-size: 18px;
    padding: 0.5rem 3rem;
}

.checkout-iagree-input {
    transform: scale(1.5);
}

.home_page_heading_div_cls {
    font-size: 22px;
    color: #232c63;
    /* font-weight: 500; */
    letter-spacing: 0.8px;
    flex-wrap: wrap;
    font-family: 'Poppins';
    font-family: 'NotoSans-Medium';
    /* margin-top: -27px; */
}

.checkout-response-page-btn-cls {
    background-color: #fff;
    color: #232c63;
    border: 1px solid;
    font-family: 'NotoSans-Medium';
    border-radius: 5px;
    padding: 0.5rem 2rem;
    margin-top: 1rem;
}

.img-thumbnail-cls-for-services {
    background-color: var(--bs-body-bg);
    /* border: var(--bs-border-width) solid var(--bs-border-color); */
    width: 100%;
    height: auto;
}



.blog-swiper{
    overflow: hidden;
    position: relative;
    padding: 50px 0px 80px 0px;
}



.blog-swiper .swiper-slide{
    transform: scale(1.1);
    z-index: 1;
    opacity: 1;
    transition: transform 0.3s ease;

}

.blog-swiper .swiper-slide-active {
    transform: scale(1.4);
    
    z-index: 2;
    opacity: 1;
}

@media (max-width: 426px){
    .blog-swiper .swiper-slide-active {
        transform: scale(2.4);
        
        z-index: 2;
        opacity: 1;
    }
}

.blog-swiper .swiper-wrapper{
    overflow: visible;
    display: flex;
    align-items: center;
}


@media (max-width: 768px){
    .blog-swiper .swiper-pagination{
        display: none !important;
    }

    .blog-swiper .swiper-button-next, .swiper-button-prev{
        display: none !important;
    }
}



.farm-equipments {
    padding: 2rem 0;
}
.farm-equipments-team {
    padding: 2rem 0;
}


@media screen and (min-width: 1024px) {
    .farm-equipments {
        padding: top 54px;
        /* padding-top: 137px; */
    }
  }
@media screen and (min-width: 1024px) {
    .farm-equipments-team {
        /* padding: top 54px; */        
        padding-top: 225px;
    }
  }

@media screen and (min-width: 1024px) {
    .doc-equipments {
        padding-top: 76px;
    }
  }

  /* .doc-equipments .slickadj{
    margin-bottom: 40px;
  } */

.home.market-place-card-cls {
    background-color: #fff;
    padding: 7px;
    border-radius: 8px;
    height: 402px;
}

.home-market-place-cattle-img-cls {
    height: 202px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.home-market-place-card-body {
    margin: 1rem 0.5rem 0 0.5rem;
}

.home-market-place-medicine-name {
    color: #212121;
}

.home-market-place-city-name {
    color: #606060;
    font-size: 14px;
    margin-top: 1rem;
}

.home-market-place-view-btn-cls {
    width: 100%;
    padding: 0.3rem 0;
    font-size: 14px;
}

.farm-equi-card.cursor-pointer {
    border: 1px solid #848484;
    background-color: #f9f9f9;
    border-radius: 8px;
    height: 438px;
}

.home-farmequip-img-cls {
    height: 15rem;
    width: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;

}

.farm-equipments-section-view-all {
    background-color: #f9f9f9;
    border: 1px solid #848484;
    border-radius: 8px;
    height:438px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-testimonials-avatar {
    border-radius: 50%;
    height: 70px;
    width: 70px;
}

@media (max-width: 425px) {
    .home-testimonials-avatar {
        height: 50px;
        width: 50px;
    }
}


.home-test-card {
    width: 90% !important;
    display: flex !important;
    gap: 4rem;
    margin-left: auto;
    margin-right: auto;
}

.testimonial-description {
    color: #474747;
    font-size: 18px;
    line-height: 1.8;
    margin-top: 0.5rem;
}

.home-testimonials-play-btn {
    margin-top: 1rem;
    font-size: 18px;
    border-radius: 50%;
    background-color: #DC7F92 !important;
}

.home-count-design {
    font-size: 20px;
    color: #232C63;
    font-family: 'NotoSans-Medium';
    margin: -1rem -1px;
}

.home-app-download-section-main {
    display: flex;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    gap: 2rem;
}




.sample-images-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 59px;
  }
  
  .sample-image {
    flex: 1;
    min-width: 150px;
  }
  
  .sample-img {
    width: 100px;
    height: 100px;
  }

  

  
  .mobile-app-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  }

  @media (max-width: 1284px) {
    .mobile-app-image {
        height: 400px !important;
    }
  }
  @media (max-width: 1199px) {
    .mobile-app-image {
        height: 600px !important;
    }
  }
  @media (max-width: 767px) {
    .mobile-app-image {
        display: none !important;
    }
  }

  .new_homebackground{
    background-color: #fff !important;
  }
  
  .home_app_text-container {
    position: relative;
    z-index: 1; /* Ensure it stays on top of the image */
    padding-top: 40px; /* Adjust padding as needed */
  }
  
  @media (min-width: 768px) {
    .home_app_text-container {
      padding-left: 249px; /* Adjust padding for larger screens */
    }
  }     
  



.home-app-download-section-main {
  padding: 0px 0px 0px 20px;
}

.app-download-img-qr {
  /* width: 80px; Adjust size as needed */
  width: 114px;
  
}

@media (max-width: 992px){
    .mobile_app_image{
        display: none;
    }
}

.app-download-img-playstore {
  width: 120px; /* Adjust size as needed */
  
}

.img-responsive {
    height: 341px;
    width: 100%;
  }
  
 

  @media (max-width: 525px) {
    .img-responsive {
      height: 100%; /* Adjust the height as needed for smaller screens */
    }
  }
.img-responsive_medicine {
    height: 431px;
    width: 100%;
  }

  @media (max-width: 1140px) and (max-width: 2560px) {
    .img-responsive_medicine {
      height: 500px; /* Fixed height for screen widths between 1140px and 2560px */
    }
  }
 
  
  @media (max-width: 525px) {
    .img-responsive_medicine {
      height: 100%; /* Adjust the height as needed for smaller screens */
    }
  }

  @media(max-width: 348px) {
    .img-responsive_medicine{
        height: 150px;
        object-fit: contain;
        padding-top: 50px ;
    }
  }
  

.first_card{
    position: relative;
  width: auto;
}

.first_card_h1{
    font-size: clamp(1rem, 3vw, 3rem)
}

.first_card_h1_tamil{
    font-size: clamp(0.7rem, 2.5vw, 2.5rem)
}

.first_card_h1_marati{
    font-size: clamp(1rem, 2.5vw, 3rem)
}


.second_card{
    position: absolute;
    top: 620px;
    /* right:25%; */
    align-items: center;
    width: auto;
}

@media (max-width: 1024px){
.banner-below-img{
    top: 65% !important;
}
}

@media (max-width: 1023px) {
    .hide-below-1023 {
      display: none !important;
    }
  }
  

@media screen and (min-width: 1024px) {
    .second_card {
        top: 650px;
        right: 24%;
    }
}
@media screen and (min-width: 1440px) {
    .second_card {
        top: 709px;
        right: 26%;
    }
}
@media screen and (min-width: 1800px) {
    .second_card {
        top: 898px;
        right: 32%;
    }
}
@media screen and (min-width: 2560px) {
    .second_card {
        top: 1300px;
        right:38%
    }
}



@media screen and (max-width: 425px) {
    .new_home-page-consultationandtreatment {
        margin-top: 40px !important;
    }
}
@media screen and (max-width: 1023px) {
    .new_home-page-consultationandtreatment {
        margin-top: 100px;
    }
}

@media screen and (min-width: 1024px) {
    .new_home-page-consultationandtreatment {
        background-color: #F6F7FF;
        padding: 2.5rem 0;
        border-radius: 10px;
        margin-bottom: 22px;  
        margin-top: 170px;   
    }
}
@media screen and (min-width: 1440px) {
    .new_home-page-consultationandtreatment {
        background-color: #F6F7FF;
        padding: 2.5rem 0;
        border-radius: 10px;
        margin-bottom: 22px;
        margin-top: 150px; 
    
    }
}
@media screen and (min-width: 1800px) {
    .new_home-page-consultationandtreatment {
        background-color: #F6F7FF;
        padding: 2.5rem 0;
        border-radius: 10px;
        margin-bottom: 22px;  
        margin-top: 140px;    
  
    }
}
@media screen and (min-width: 2560px) {
    .new_home-page-consultationandtreatment {
        background-color: #F6F7FF;
        padding: 2.5rem 0;
        border-radius: 10px;
        margin-bottom: 22px;
margin-top: 155px;    
    }
}
/* .footer-col-content {
  display: flex;
  justify-content: flex-end;
} */

.footer-col-content li {
  list-style: none;
}

.cursor-pointer {
  cursor: pointer;
}









.seller-profile-subs-desc {
    margin: 0.5rem 1rem;
    color: #757575;
}

.animalpost-disease-list-div {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
}

.recent-disease-in-add-animal {
    display: flex;
    gap: 0.5rem;
    border: 1px solid #bfbfbf;
    padding: 0.2rem 0.8rem;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #b1b1b1b0;
    background-color: #f0eaeae3;
}

.animalpost-agree-label {
    color: mediumblue;
    text-decoration: underline;
}

.custom-card-consultationandtreatment {
    background-color: rgb(237 237 237);
    padding: 2.5rem 0;
    border-radius: 10px;
}
.new_home-page-consultationandtreatment {
    background-color: #F6F7FF;
    padding: 2.5rem 0;
    border-radius: 10px;
    margin-bottom: 22px;
    color: #232c63;
}
.consultation-diseases-card{
    margin-top: 26px;
    
}

.consultation-diseases-card.cursor-pointer {
    background-color: #fff;
    /* padding: 0.5rem; */
    border-radius: 15px;
    margin-bottom: 43px;
}
.consulattion-text{
    padding: .5rem;
    height: 161px;
    overflow-y: auto;
}

/* .consulattion-text .alignText{
    padding-left: 32px !important;
} */

.consult-common-disease-img {
    height: 180px;
    width: 100% !important;
    object-fit: fill !important;
    /* object-fit: cover; */
}
.Team-common-disease-img {
    height: 100%;
    /* height: 380px; */
    width: 100%;
    object-fit: fill;
    border-radius: 21px 21px 0px 0px ;
    /* object-fit: cover; */
    /* margin-top: 1px; */
}
.Core-Team-common-disease-img {
    /* height: 260px;
    width: 100%; */
    object-fit: cover;
    width: 100%;
}
@media (max-width: 768px) {
    .Core-Team-common-disease-img {
      height: 100%; 
      width: 100%;
      /* margin-bottom: 20px;
      padding-top: 20px; */
    }
  }
.Core-Team-Grid-img {
    height: 48.7%;
    width: 100%;
    object-fit: cover;
}
.Doctor-Team-img {
    height:20%;
    width: 100%;
    object-fit: cover;
}
.Doctor-Team {
    padding-bottom: 20px; 
}

.CommonDieasesschat-button {
    background-color: #fff;
    color: #232c63;
    border: 1.5px solid #232c63;
    border-radius: 8px;
    padding: 0 10px;
    font-family: 'NotoSans-Medium';
    font-size: 18px;
    
}

.CommonDieasesscall-button {
    background-color: #232c63;
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 18px;
}

.consult-frequent-div {
    padding: 1.5rem 0;
}

.frequent-heading-cls {
    font-family: 'NotoSans-Medium';
    font-size: 22px;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
    margin-bottom: 1rem;
color:#232C63 !important;
}

.frequent-questions-span {
    font-size: 20px;
    padding-left: 10px;
}

.fre-ques-map-div:last-child .individual-question {
    border-bottom: none;
}

.consult-doctors-list-main-div {
    background-color: #f9f9f9;
    padding: 1rem 0.5rem;
    text-align: center;
    border-radius: 8px;
}

.treatment-doctor-view-all-div {
    height: 24.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.call-floating-btn-cls {
    position: fixed;
    right: 20px;
    bottom: 100px;
    z-index: 999;
    font-size: 35px;
    background-color: limegreen;
    border-radius: 50%;
    height: 55px;
    width: 55px;
    text-align: center;
    line-height: 50px;
    animation: pulse 1s linear infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.topnavbar-menu-col-3-main-div-big {
    align-items: center;
    gap: 1rem;
    margin-right: 30px;
}

.login-modal-row {
    padding-left: 0;
    padding-right: 0;
}

.otp-tet-field-input-cls {
    width: 2em;
    height: 2em;
    font-size:24px;
    text-align: center;
    border-radius: 5px;
}
.login-button{
        font-size: 16px;
        width: auto;
        white-space: nowrap;
        margin-left: 10px; 
      
}
@media (min-width: 1400px) {
    .product_list_card_qty_select_cls {
        margin-top: 10px;
    }
}



@media (max-width: 1199px) {
    .seller_profile_page_register_form_grup {
        width: 70%;
    }

    .farm-equipment-view-all {
        height: 29.2rem;
    }

    .topnav-menu2-col-cls {
        gap: 1rem;
    }
}

@media only screen and (max-width: 992px) {
    .market-animals-cate-filter-main-div {
        display: none !important;
    }

    .marketplace_cattle_category_for_small_display {
        display: block !important;
        overflow-y: auto;
    }

    .market_place_smal_screen_filter {
        margin-bottom: 0.5rem;
    }

    .seller_profile_page_register_form_grup {
        width: 100% !important;
    }

    .product_details_page_btns_col_cls {
        gap: 1rem;
    }

    .column-padding-remover {
        padding-left: 0;
        padding-right: 0;
    }

    .cart-product-price-col {
        margin-top: 1rem;
    }

    .cart_product_remove_btn {
        justify-content: flex-end;
    }

    .cart-page-product-image-name-div {
        flex-direction: column;
    }

    .cart-product-qty-maind-cls {
        margin-left: 146px;
    }

    .discount-code {
        width: 50%;
    }

    .login_title img {
        width: 176px;
    }

    .otp-tet-field-input-cls {
        width: 1.7em;
        height: 1.5em;
    }

    .opt-col2-div-cls {
        margin: 2rem 0;
    }

}

@media only screen and (max-width: 768px) {
    .marketplace_cattle_grid_btn_col2 {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        gap: 0px !important;
    }

    .seller_profile_page_main_div_small {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    .seller_profile_page_main_div {
        display: none;
    }

    .products-list-view-bottom-price-btn-div {
        flex-wrap: wrap !important;
        gap: 12px !important;
    }

    .product-details-col2-content-cls {
        /* margin-right: 0; */
        /* margin-left: 0; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cattle_list_milk_productions_span {
        display: flex;
    }

    .marketplace_services_img_cls {
        width: 8rem;
        height: 8rem;
    }

    .product-grid-view-name {
        font-size: 18px;
    }

    .marketplace_services_location_div {
        font-size: 16px;
    }

    .marketplace_view_mobileno {
        font-size: 16px;
    }

    .cattle_list_milk_productions_span {
        margin-left: 0px !important;
    }

    .home-search-div {
        margin-top: 0.5rem;
    }

    @media screen and (max-width: 320px) {
        .home-search-div {
            flex-direction: column; /* Stack items vertically */
            align-items: stretch; /* Stretch items to fill width */
        }
    
        .home-search {
            margin-bottom: 10px; /* Add some spacing between input and icon */
            width: calc(100% - 23px); /* Reduce input width to fit smaller screens */
        }
    
        .home-search-icon {
            width: 20px; /* Adjust the size of the search icon */
            height: 20px;
        }
    }
    .product-list-view-desc {
        width: 90%;
    }

    .card_grid_view_img {
        height: 13rem;
    }

    /* .slick-slide>div {
        width: 100%;
    } */

    .checkout-stepper-vertical-line {
        width: 4rem;
    }

    .checkout-stepper-map-div {
        gap: 0;
    }

    .checkout-stepper-main-div {
        gap: 0;
    }

    .checkout-products-before-map-div {
        margin-top: 2rem;
    }

    .text-section {
        max-width: 100%;
    }

    .slick-dots {
        display: none !important;
        /* margin-bottom: 30px; */
    }

    .farm-equipment-view-all {
        height: 27.9rem;
    }

    .home-app-download-section-main {
        width: 100%;
    }

    .login-modal-row {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .maretplace-cattle-view {
        margin-bottom: 15px;
    }

    .marketplace-3img-front {
        height: 20rem;
    }

    .marketplace-4img-video {
        height: 20rem;
    }

    .marketplace-4img-body {
        height: 8rem;
    }

    img.marketplace-4img-side {
        height: 8rem;
    }

    .marketplace-4img-front {
        height: 12rem;
    }

    .marketplace-2img-front {
        height: 20rem;
    }

    .marketplace-2img-video {
        height: 20rem;
    }

    .marketplace-1img-front {
        height: 20rem;
    }

    .marketplace-1img-video {
        height: 20rem;
    }

    img.marketplace_seller_profile_img {
        height: 5rem;
        width: 5rem;
    }

    .view-icon-none {
        display: none;
    }

    .marketplace-cattle-btn-col-cls {
        margin-top: 15px;
    }

    .card-grid-view-img {
        height: 13rem;
        width: 50%;
        margin: 20px 0;
    }

    .slider-arrow-custom-style-for-related-products .slick-prev {
        left: -10px !important;
    }

    .slider-arrow-custom-style-for-related-products .slick-next {
        right: -10px !important;
    }

    .our_doctors_col_btns_cls {
        position: static;
        justify-content: flex-end;
    }
}

@media only screen and (max-width: 480px) {
    .marketplace_services_card_div {
        gap: 1rem;
        flex-wrap: wrap;
    }

    .marketplace_services_img_cls {
        margin-left: auto;
        margin-right: auto;
    }

    .discount-code {
        width: 100%;
    }

    .checkout-page-btns-div {
        gap: 1rem;
    }

    .checkout-page-cancel-btn {
        padding: 0.5rem 2rem;
    }

    .checkout-page-ok-btn {
        padding: 0.5rem 2rem;
    }

    .modal_close_icons {
        right: 10px;
        top: 0px;
    }
}

.video-list{
    margin-bottom: 15px;
    font-size: 22px;
}
.achievements-section{
    padding: 0px;
}

.right-padding{
   margin-left: 24px;
}

.uniform-input {
    width: 100%;
    height: 40px; /* or any desired height */
    box-sizing: border-box;
  }
  
  .user-farmdeatil-tab1-details .row-padding-remover {
    padding-left: 0;
    padding-right: 0;
  }
  
  .user-profile-cattle-title {
    font-size: 18px; 
    font-weight: bold;
    color:#232C63;
  }
  
  .form-group {
    width: 100%;
  }
  
 
  
  .farm-input-box {
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #dc7f92;
    padding: 0.5rem;
  }
  
  .text-danger {
    color: #dc3545;
    font-size: 0.875rem;
  }

  .promo-text{
    /* margin-bottom: 5px; */
    font-size: 16px;
    color: #606060;
}
.product-deatil-icon {
    font-size: 24px; /* Adjust the size as needed */
    color: green;
}

.feature {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Space between features */
}

.feature p {
    margin-left: 10px; /* Space between icon and text */
}
.limited-text-in-3-line {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;   
    text-overflow: ellipsis;
    line-height: 1.2em; 
    max-height: calc(1.2em * 5); 
}
.limited-text-in-2-line {
        display: -webkit-box;
        -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em; 
    max-height: calc(1.2em * 2);
    text-align: center; 
}

.limited-question-text {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em; /* Adjust line height if necessary */
    max-height: calc(1.2em * 2); /* 2 lines * line-height */
    margin-bottom: 3px;
}

.price-container{
    color:#232c63;
    align-items: center;
}

.medicine-section-container {
    position: relative;
    width: 100%;
  }

  
  /* .image-container {
    position: relative;
    width: 100%;
  } */
  
  .medicine-image {
    width: 100%;
    height: 100%;
  }
  
  .overlay-text {
    position: absolute;
    top: 20px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
    color: rgb(10, 10, 10); /* Adjust text color as needed */
    /* background-color: rgba(0, 0, 0, 0.5); */
    padding: 10px; /* Optional: Add padding */
    z-index: 2;
  }
  
  .overlay-button {
    position: absolute;
    bottom: 20px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
    z-index: 2;
  }


  .doc-heading-div {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .product-row {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .product-item {
    flex: 3 0 auto;
    margin-right: 15px; /* Adjust spacing between items */
    align-self: center;
  }
  
  .product-image {
    width: 100px; /* Adjust the width as needed */
    height: auto;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-privacy-link {
    color: #232c63 !important;
    text-decoration: underline !important;
    margin-bottom: 5px; /* Space between the link and the copyright text */
    font-size: 14px;
    
  }
  
  .footer-copyright-text {
    margin: 0;
  }


  .custom-nav-tabs {
    border: none;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-wrap: wrap;
  }
  
  .custom-nav-tabs .nav-item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .custom-nav-tabs .nav-link {
    border-radius: 5px;
    background-color: rgb(217, 210, 212);
    color: #232c63;
    padding: 8px 16px;
    text-align: center;
  }
  
  .custom-nav-tabs .nav-link.active {
    color: #fff;
  }
  
  /* Tablet Screens */
  @media (max-width: 768px) {
    .custom-nav-tabs {
      font-size: 18px;
      flex-direction: column;
    }
  
    .custom-nav-tabs .nav-item {
      margin-right: 0;
      width: 100%;
    }
  
    .custom-nav-tabs .nav-link {
      width: 100%;
    }
  }
  
  /* Mobile Screens */
  @media (max-width: 480px) {
    .custom-nav-tabs {
      font-size: 16px;
    }
  
    .custom-nav-tabs .nav-item {
      margin-bottom: 8px;
    }
  }

  
  .{
    display: inline-block;
    color: #000;
    position: relative;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s ease;
    color: rgb(5, 5, 5);
  }
  
  .view-more-button:hover {
    color: rgb(5, 5, 5);
  }
  
  .view-more-icon {
    vertical-align: middle;
    fill: rgb(35, 44, 99);
    height: 22px;
    transition: fill 0.3s ease;
  }
  
  /* Tablet Screens */
  @media (max-width: 768px) {
    .view-more-button {
      font-size: 16px;
      padding: 8px 16px;
    }
  
    .view-more-icon {
      height: 20px;
    }
  }
  
  /* Mobile Screens */
  @media (max-width: 480px) {
    .view-more-button {
      font-size: 16px;
      padding: 6px 12px;
    }
  
    .view-more-icon {
      height: 18px;
    }
  }
  

.activity_main_div_position{
    position: relative !important;
}

  .acitivity-container{
    background-color: #FFF3F5;
    padding: 2rem 0px;
}

.activity-shortDiscrip {
    position: absolute !important;
    cursor: pointer;
    top: 13.5rem;
    z-index: 100;
    height: 5rem;
    width: 100%;
    overflow: hidden;
    background-color: #ededed;
    opacity: 1;
    border-color: #000000;
    border-width: 5px;
    border-radius: 14px 14px 14px 14px;
  }

  .activity-hover {
    position: absolute !important;
    cursor: pointer;
    top: 11.3rem;
    z-index: 100;
    height: 6.7rem;
    width: 100%;
    overflow: hidden;
    background-color: #ededed;
    opacity: 0.85;
    border-radius: 14px 14px 14px 14px ;
  }

  .activity-ShortTitle {
    font-size: 18px;
    line-height: 36px;
    font-weight: 400;
    color: #232C63;
    padding: 5px 0px 0px 15px;
  }
  .activity-hoverShortTitle {
    font-Size: 18px;
    line-height: 36px;
    font-Weight: 400;
    opacity: 100%;
    color: #232C63;
    padding: 5px 0px 0px 15px;
  }
  .activity-descripDiv {
    margin: 0rem 0.5rem 0rem 0.2rem;
  }
  .activity-Discrip {
    height: 2.5em;
    max-height: 15em;
    line-height: 14.2em;
    font-Size: 14px;
    font-Weight: 500;
    color: #232C63;
    opacity: 0;
    padding: 0px 0px 0px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .activity-hover_Discrip{
    max-height: 21rem;
    line-height: 14.2em;
    font-Size: 14px;
    font-Weight: 500;
    color: #232C63;
    padding: 5px 15px 0px 15px;
    height: 70%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }